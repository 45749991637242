import { useState } from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import Videos2021 from './Videos/Videos2021';

const styles = {
  activeImagesStyles: {
    fontSize: 26,
    color: '#cc0000',
    // fontFamily: 'HelveticaforTargetRegular',
    cursor: 'pointer',
    border: 'none',
    background: '#ffffff',
    margin: '0',
    fontWeight: 'normal',
  },
  imagesStyles: {
    fontSize: 26,
    color: '#aaaaaa',
    // fontFamily: 'HelveticaforTargetRegular',
    cursor: 'pointer',
    border: 'none',
    background: '#ffffff',
    margin: '0',
    fontWeight: 'normal',
  },
};

const Data2021 = ({ classes }) => {
  const [imagesTab, setImages] = useState(1);
  return (
    <Grid>
      <Grid container style={{ marginBottom: 40 }}>
        <h2 className={imagesTab === 1 ? classes.activeImagesStyles : classes.imagesStyles} onClick={() => setImages(1)}>
          Videos
        </h2>
      </Grid>
      <Videos2021 />
    </Grid>
  );
};

export default withStyles(styles)(Data2021);
