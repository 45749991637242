import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import RenderImages from './RenderImages';
import RenderVideos from './RenderVideos';
import Data2021 from './Data2021';

const styles = {
  activeImagesStyles: {
    fontSize: 26,
    color: '#cc0000',
    // fontFamily: 'HelveticaforTargetRegular',
    cursor: 'pointer',
    border: 'none',
    background: '#ffffff',
  },
  imagesStyles: {
    fontSize: 26,
    color: '#666666',
    // fontFamily: 'HelveticaforTargetRegular',
    cursor: 'pointer',
    border: 'none',
    background: '#ffffff',
  },
};

const RenderGallery = ({ classes, eventYear, galleryData }) => {
  const [galleryType, setGalleryType] = useState('videos');
  useEffect(() => {
    if (eventYear !== 2021 && galleryData[eventYear]['images'].length <= 0) {
      setGalleryType('videos');
    }
  }, [eventYear, galleryData]);
  return (
    <>
      {eventYear === 2021 ? (
        <Data2021 />
      ) : (
        <Grid>
          <Grid container style={{ marginBottom: 40 }}>
            {galleryData[eventYear]['images'].length > 0 && (
              <button
                className={galleryType === 'images' ? classes.activeImagesStyles : classes.imagesStyles}
                style={{ marginRight: 20 }}
                onClick={() => setGalleryType('images')}
              >
                Images
              </button>
            )}
            {galleryData[eventYear]['videos'].length > 0 && (
              <button className={galleryType === 'videos' ? classes.activeImagesStyles : classes.imagesStyles} onClick={() => setGalleryType('videos')}>
                Videos
              </button>
            )}
          </Grid>
          {galleryType === 'images' ? (
            <RenderImages galleryImages={galleryData[eventYear]['images']} />
          ) : (
            <RenderVideos galleryVideos={galleryData[eventYear]['videos']} />
          )}
        </Grid>
      )}
    </>
  );
};

export default withStyles(styles)(RenderGallery);
