/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Input } from '@mui/icons-material';
// import MuiDataTable from 'mui-datatables';
import { Button } from '@mui/material';
// import WorkshopParticipants from './WorkshopParticipants';
import ParticipantBooking from '../ParticipantBooking';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { useGetParticipantBookingInfoQuery, useValidateParticipantQuery } from '../../../api/invenue';
import { useGetParticipantsOfEventQuery } from '../../../api/registration';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import { approved, attended, blocked, onHold, registered, rejected } from './Status';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import PrintIcon from '@mui/icons-material/Print';
import PrintCard from '../PrintCard';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function RenderStatus(props) {
  const { status } = props;

  return (
    <Typography style={{ fontWeight: 'bold' }}>
      {status === approved
        ? 'APP'
        : status === blocked
        ? 'BLK'
        : status === registered
        ? 'REG'
        : status === attended
        ? 'ATT'
        : status === onHold
        ? 'HLD'
        : status === rejected
        ? 'REJ'
        : 'WLK'}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function RegistrationTabs() {
  const [value, setValue] = useState(0);
  const [openWorkshops, setOpenWorkshops] = useState(false);
  const [openPrintJob, setOpenPrintJob] = useState(false);
  const [cookies, setCookie] = useCookies(['token']);
  const [searchPayload, setSearchPayload] = useState(null);
  const [bookingSearchPayload, setBookingSearchPayload] = useState(null);
  const [entry, setCurrentEntry] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [participantBookingInfo, setParticipantBookingInfo] = useState({});
  const [printingObj, setPrintingObj] = useState({});
  const eventId = useSelector((state) => state.tenant.eventId);

  const { data: participantsData, isFetching: participantsLoading } = useGetParticipantsOfEventQuery(
    { filters: {}, eventid: eventId, auth: cookies?.token },
    { refetchOnMountOrArgChange: true }
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (participantsData?.length > 0) {
      setParticipants(participantsData);
    }
  }, [participantsData]);

  const {
    data: participant,
    isFetching: participantDataFetching,
    isError: participantError,
    error: participantErrorData,
  } = useValidateParticipantQuery(searchPayload, { skip: !searchPayload });

  const {
    data: participantBookingData,
    isFetching: participantBookingDataFetching,
    isError: participantBookingDataError,
    error: participantBookingErrorData,
  } = useGetParticipantBookingInfoQuery(bookingSearchPayload, { skip: !bookingSearchPayload });

  const handleOpen = ({ user, status }) => {
    if (status !== attended) {
      setSearchPayload({
        email_id: user.email_id,
        id: user._id,
        eventId,
        auth: cookies?.token,
        session: new Date(),
      });
      setOpenWorkshops(true);
    } else {
      const printObj = {};
      printObj['user'] = {
        name: user.name,
        company: user.company,
      };

      // printObj['booking'] = {
      //   _id: participantBookingInfo.program._id,
      // };
      // const pgmId = participantBookingInfo['program']['_id'];
      // printObj['program'] = {
      //   [pgmId]: participantBookingInfo['program'],
      // };

      setPrintingObj({ ...printingObj, ...printObj });
      setBookingSearchPayload({
        id: user._id,
        eventId,
        auth: cookies?.token,
        session: new Date(),
      });
      setOpenPrintJob(true);
    }
  };
  useEffect(() => {
    if (participant && participant?.data) {
      setCurrentEntry(participant);
    } else if (participant && participant?.data?.status) {
      console.log('hande error here');
      // setErrorDialog(true);
      // setErrorData(participant);
    }
  }, [participant]);

  useEffect(() => {
    if (participantBookingData && participantBookingData.length > 0) {
      setParticipantBookingInfo(participantBookingData[0]);
      const printObj = {};
      printObj['booking'] = {
        _id: participantBookingData[0].program._id,
      };
      const pgmId = participantBookingData[0]['program']['_id'];
      printObj['program'] = {
        [pgmId]: participantBookingData[0]['program'],
      };
      setPrintingObj({ ...printingObj, ...printObj });
    } else if (participantBookingData && participantBookingData?.data?.status) {
      console.log('hande error here');
      // setErrorDialog(true);
      // setErrorData(participant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantBookingData]);
  useEffect(() => {
    if (!participantDataFetching && participantErrorData) {
      setSearchPayload(null);
      // setCurrentError({
      //   status: participantErrorData?.status,
      //   message: participantErrorData?.data?.message,
      // });
    }
  }, [participantErrorData, participantError, participantDataFetching]);

  const handleClose = () => {
    setOpenWorkshops(false);
    setSearchPayload(null);
    setOpenPrintJob(false);
  };
  const tablecolumns = [
    {
      field: 'about_user',
      headerName: 'Name',
      type: 'text',
      flex: 1,
      valueGetter: (params) => params.row.user.name,
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'text',
      flex: 1,
      valueGetter: (params) => params.row.user.email,
    },
    {
      field: 'mobile',
      headerName: 'Mobile',
      type: 'text',
      flex: 1,
      valueGetter: (params) => params.row.user.mobile,
    },
    {
      field: 'company',
      headerName: 'Company',
      type: 'text',
      flex: 1,
      valueGetter: (params) => params.row.user.company,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'text',
      flex: 1,
      valueGetter: (params) => params.row.status,
      renderCell: (params) => {
        return (
          <div>
            <RenderStatus status={params.row.status} />
          </div>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      type: 'text',
      flex: 1,
      valueGetter: (params) => params.row.status,
      renderCell: (params) => (
        <div>
          <Button onClick={() => handleOpen(params.row)}>{params.row.status === attended ? <PrintIcon /> : <Input />}</Button>
        </div>
      ),
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      {openWorkshops && entry ? (
        <Dialog onClose={handleClose} open={openWorkshops} fullWidth={true} maxWidth={'xl'}>
          <DialogContent>
            {participantDataFetching ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
              <ParticipantBooking
                entry={entry}
                eventId={eventId}
                auth={cookies?.token}
                setCurrentEntry={setCurrentEntry}
                setSearch={() => {}}
                setUserId={() => {}}
                handleClose={handleClose}
              />
            )}
          </DialogContent>
        </Dialog>
      ) : null}

      {openPrintJob ? (
        <Dialog onClose={handleClose} open={openPrintJob} fullWidth={true} maxWidth={'xl'}>
          <DialogContent>
            {participantDataFetching ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
              <PrintCard user={printingObj.user} booking={printingObj.booking} programs={printingObj.program} />
            )}
          </DialogContent>
        </Dialog>
      ) : null}

      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          '& .MuiTabs-flexContainer button.Mui-selected': {
            color: '#cc0000',
          },
          '& .MuiTabs-flexContainer button': {
            fontSize: '20px !important',
            textTransform: 'none',
          },
        }}
      >
        <Tabs sx={{ color: '#CC0000 !important' }} value={value} onChange={handleChange} aria-label='basic tabs example'>
          <Tab label='Search' {...a11yProps(1)} />
          {/* <Tab label='Engagements' {...a11yProps(1)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <DataGrid
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          getRowId={(row) => row._id}
          rows={participants}
          columns={tablecolumns}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={1}>
        <WorkshopParticipants />
      </CustomTabPanel> */}
    </Box>
  );
}
