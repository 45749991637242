import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import cardimg from "./cards_BG_new.png";
import { useParams } from "react-router-dom";

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div
        style={{
          height: "9.9cm",
          position: "relative",
          width: "6.7cm",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column-reverse",
          alignItems: "center",
        }}
      >
        <div
          style={{
            bottom: "0%",
            left: "10%",
            width: "84%",
            height: "4.5cm",
            fontSize: "15pt",
            textAlign: "center",
            fontWeight: "bold",
            lineHeight: "18pt",
          }}
        >
          <h3 style={{ marginBottom: 0, marginTop: "22px" }}>
            {this.props?.name}
          </h3>
          {/* <h3 style={{ marginBottom: 0, marginTop: "15px" }}>
            <span>{this.props?.program}</span>
          </h3>
          <h4 style={{ margin: 0, fontSize: 13 }}>{this.props?.title}</h4> */}
        </div>
      </div>
    );
  }
}

class ComponentToPrintTest extends React.Component {
  render() {
    return (
      <div
        style={{
          height: "9.9cm",
          position: "relative",
          width: "6.7cm",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column-reverse",
          alignItems: "center",
        }}
      >
        <div
          style={{
            bottom: "0%",
            left: "10%",
            width: "84%",
            height: "4.2cm",
            fontSize: "18pt",
            textAlign: "center",
            fontWeight: "bold",
            lineHeight: "18pt",
          }}
        >
          <h3 style={{ marginBottom: 0, marginTop: "35px" }}>
            {this.props?.name}
          </h3>
          {/* <h3 style={{ marginBottom: 0, marginTop: "15px" }}>
            <span>{this.props?.program}</span>
          </h3>
          <h4 style={{ margin: 0, fontSize: 13 }}>{this.props?.title}</h4> */}
        </div>
      </div>
    );
  }
}

function PrintCard(props) {
  const { user = {}, programs = {}, booking = {} } = props;
  const { name, company } = user;
  const componentRef = useRef();
  const buttonRef = useRef();

  const getWorkshopDetails = (id, programsList, flag) => {
    return programsList?.[id]?.[flag];
  };

  useEffect(() => {
    if (buttonRef.current && name) {
      setTimeout(() => {
        buttonRef.current.click();
      }, 500);
    }
  }, [name]);

  return (
    <Box
      sx={{
        pt: 4,
        pb: 4,
        mt: 5,
        mb: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <ReactToPrint
        trigger={() => (
          <button ref={buttonRef} style={{ marginBottom: 20, display: "none" }}>
            Print this out!
          </button>
        )}
        content={() => componentRef.current}
      />
      <Box
        sx={{
          boxShadow: 4,
          background: `url(${cardimg}) no-repeat`,
          backgroundSize: "cover",
        }}
      >
        <ComponentToPrint
          name={name}
          company={company}
          ref={componentRef}
          program={getWorkshopDetails(
            booking?._id,
            programs,
            "_secondary_name_text"
          )}
          title={getWorkshopDetails(
            booking?._id,
            programs,
            "_short_title_text"
          )}
        />
      </Box>
    </Box>
  );
}

export const PrintTest = () => {
  const { name } = useParams();
  console.log(name);
  const componentRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    if (buttonRef.current && name) {
      setTimeout(() => {
        buttonRef.current.click();
      }, 500);
    }
  }, [name]);

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button ref={buttonRef} style={{ marginBottom: 20, display: "none" }}>
            Print this out!
          </button>
        )}
        content={() => componentRef.current}
      />
      <ComponentToPrintTest ref={componentRef} name={name} />
    </>
  );
};

export default PrintCard;
