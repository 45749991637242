import typo from "../../assets/json/typho.json";
export default function _findFontSize(
  typoVariant,
  sizeVariant,
  device,
  responsive = false
) {
  var fontsizeVal = {
    sizeVal: "",
  };
  typo.forEach((variant) => {
    if (variant.slug === typoVariant) {
      variant.fontSize.forEach((sizes) => {
        if (sizes.name === sizeVariant) {
          sizes.values.forEach((fontSize) => {
            if (fontSize.name === device) {
              fontsizeVal.sizeVal = responsive
                ? fontSize.range.viewportSize
                : fontSize.range.value
                ? fontSize.range.value
                : fontSize.range.default;
            }
          });
        }
      });
    }
  });
  return fontsizeVal;
}
