/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { Grid, TextField } from '@mui/material';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IsEmail } from '../../Utils/utils';
import { Helmet } from 'react-helmet';
import { crossSvg } from '../../assets/Images/Images';
import { setDialogStatus, setEmailId, setExistingUserData, useValidateUserMutation } from '../../api/validateUser';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const styles = {
  customButton: {
    fontSize: 14,
    padding: '12px 26px',
    borderRadius: 50,
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      background: '#333333',
      boxShadow: 'none',
    },
    '&:focus': {
      background: '#9B6A42',
      outline: '1px dotted #000 !important',
      // outlineOffset: 2,
    },
    '&:focus-within': {
      background: '#9B6A42',
      outline: '1px dotted #000 !important',
      // outlineOffset: 2,
    },
    '&:focus-visible': {
      background: '#9B6A42',
      outline: '1px dotted #000 !important',
      // outlineOffset: 2,
    },
    '&:disabled': {
      background: '#D6D6D6',
      color: '#333333',
      cursor: 'default',
    },
  },
  cancelButton: {
    fontSize: 14,
    padding: '12px 26px',
    borderRadius: 50,
    background: '#ffffff',
    border: '1.5px solid #666666',
    color: '#333333',
    // fontFamily: 'HelveticaforTargetBold',
    fontWeight: '700',
    cursor: 'pointer',
    '&:hover': {
      background: '#333333',
      boxShadow: 'none',
      color: '#FFF',
    },
    '&:focus': {
      background: '#333333',
      boxShadow: 'none',
      color: '#FFF',
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
  },
  root: {
    '& .MuiOutlinedInput-root': {
      height: 50,
      '& fieldset': {
        border: '2px solid #e6e6e6',
        fontSize: 14,
        borderRadius: `50px`,
        // fontFamily: 'HelveticaforTargetRegular',
      },
      '&.Mui-focused': {
        borderRadius: `50px`,
        outline: '2px dotted #666666',
        outlineOffset: 2,
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: '#7f7f7f !important',
        },
      },
    },
    '& .MuiOutlinedInput-input': {
      textIndent: 9,
      color: '#000000',
      // fontFamily: 'HelveticaforTargetRegular',
    },
    '& .MuiInputBase-input': {
      color: '#000000',
    },
  },
  errorLabel: {
    // fontFamily: 'HelveticaforTarget',
    textAlign: 'center',
    color: '#CC0000',
    fontWeight: 'bold',
    fontSize: 14,
    marginTop: 16,
  },
  signUpText: {
    marginTop: 0,
    color: '#333333',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    // fontSize: 44,
    fontWeight: 400,
    marginBottom: '20px',
  },
  underLine: {
    border: '2px solid #be854c',
    backgroundColor: '#be854c',
    width: 80,
    marginBottom: 31,
  },
  messageLabel: {
    // paddingTop: 8,
    color: '#CC0000',
    fontSize: '14px',
    // fontFamily: 'HelveticaforTargetRegular',
    textAlign: 'left',
    lineHeight: '1',
    fontWeight: '400 !important',
    marginTop: '10px',
  },
  labelStyles: {
    fontSize: 16,
    // fontFamily: 'HelveticaforTarget',
    marginBottom: 4,
    marginLeft: 18,
  },
  closeButton: {
    border: 'none',
    width: '20px',
    height: '20px',
    background: `url(${crossSvg}) no-repeat`,
    '&:focus-visible': {
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
    '&:hover': {
      opacity: 0.75,
      backgroundColor: '#ffffff',
    },
  },
  dialog: {
    borderRadius: '25px',
    '& .MuiDialog-paper': {
      maxWidth: 479,
    },
  },
  crossMark: {
    height: 17,
    width: 17,
    backgroundColor: '#ffffff',
    '&:hover': {
      opacity: 0.75,
      backgroundColor: '#fff',
    },
  },
};

function SignUpDialog({
  classes,
  storeEmailId,
  storeUserDetails,
  userSuccess = false,
  existingUser = false,
  isheader = false,
  openSignUp,
  isBanner = false,
  isRegisterPage = false,
  setopenSignUp = () => {},
  isRedirect = false,
  redirectToSignup,
  isAboutPage = false,
  isMentorship,
  isSpotReg = false,
  handleSingUpClose = () => {},
}) {
  const eventId = useSelector((state) => state.tenant.eventId);
  const [validateUser, { data: validateUserResponse }] = useValidateUserMutation();

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [emailId, setEmail] = useState('');
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState('');

  const emailRef = useRef();

  const tab = useMediaQuery('(max-width:550px)');

  const mobile = useMediaQuery('(max-width:416px)');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(
      setDialogStatus({
        isOpenSingUp: false,
      })
    );
    if (isMobile || isMentorship || isBanner) {
      setopenSignUp(false);
    }
    if (isRegisterPage) {
      handleSingUpClose();
    }
    setOpen(false);
    setMessage('');
    setEmail('');
    setError('');
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError(false);
    setMessage('');
  };
  //   const [checkExistingUser] = useMutation(CHECK_EXISTING_USER);
  useEffect(() => {
    if (existingUser) {
      setMessage(
        <div
          role='alert'
          className={styles.messageLabel}
          style={{ width: mobile ? 223 : tab ? 300 : 375, color: '#be854c', fontWeight: 'bold', ...styles.messageLabel }}
        >
          {`Thanks for updating your information.
                    We've already saved a seat for you. Check your Inbox for details soon and head to our `}
          <NavLink to='/gallery' style={{ fontWeight: 'bold', color: '#0d6efd', cursor: 'pointer', textDecoration: 'none' }}>
            Gallery
          </NavLink>
          {` page for glimpses of past editions.`}
        </div>
      );
    } else if (userSuccess) {
      setMessage(
        <div
          role='alert'
          className={styles.messageLabel}
          style={{ width: mobile ? 223 : tab ? 300 : '95%', color: '#FFF', fontWeight: 'bold', ...styles.messageLabel }}
        >
          {`Thank you for signing up for an invitation to Target Elevate 2024.
                    We'll get back to you soon! Until then, check out our `}
          <NavLink to='/gallery' style={{ fontWeight: 'bold', color: '#FFF', cursor: 'pointer', textDecoration: 'underline' }}>
            Gallery
          </NavLink>
          {` page for glimpses of past editions.`}
        </div>
      );
    }
    window.scrollTo(0, 0);
  }, [userSuccess, existingUser]);

  useEffect(() => {
    if (isRedirect) {
      if (isMobile) {
        setopenSignUp(true);
      }
      setOpen(true);
      redirectToSignup(false);
    }
  }, [isRedirect]);

  // eslint-disable-next-line no-unused-vars
  const [validateUserResData, setValidateUserResData] = useState({});

  useEffect(() => {
    if (validateUserResponse) {
      //setValidateUserResData(validateUserResponse);

      if (validateUserResponse.status[0].status === 'REGISTERED' && validateUserResponse.status[0].year === 2024) {
        console.log('REGISTERED 2024');
        setMessage(
          <div role='alert' className={styles.messageLabel} style={{ width: mobile ? 223 : tab ? 300 : '100%', color: '#be854c', ...styles.messageLabel }}>
            {`Hey, looks like you've already applied. Keep an eye on your Inbox for updates.`}
          </div>
        );
        return;
      }
      if (validateUserResponse.status[0].status === 'APPROVED' && validateUserResponse.status[0].year === 2024) {
        console.log('APPROVED 2024');
        setMessage(
          <div
            role='alert'
            // className={styles.messageLabel}
            style={{ width: mobile ? 223 : tab ? 300 : '100%', color: '#be854c', ...styles.messageLabel }}
          >
            {`Looks like you've already registered and we've saved a seat for you! Keep an eye on your Inbox for details.`}
          </div>
        );
        return;
      }
      // change the copy of the below scenarios
      if (validateUserResponse.status[0].status === 'REJECTED' && validateUserResponse.status[0].year === 2024) {
        console.log('R 2024');
        // should i need to consider the year for rejected.
        setMessage(
          <div
            role='alert'
            // className={styles.messageLabel}
            style={{ width: mobile ? 223 : tab ? 300 : '100%', color: '#be854c', ...styles.messageLabel }}
          >
            {`Hey, looks like you've already applied. Keep an eye on your Inbox for updates.`}
          </div>
        );
        return;
      }
      if (validateUserResponse.status[0].status === 'ON_HOLD' && validateUserResponse.status[0].year === 2024) {
        console.log('On 2024');
        setMessage(
          <div
            role='alert'
            // className={styles.messageLabel}
            style={{ width: mobile ? 223 : tab ? 300 : '100%', color: '#be854c', ...styles.messageLabel }}
          >
            {`Hey, looks like you've already applied. Keep an eye on your Inbox for updates.`}
          </div>
        );
        return;
      }

      if (validateUserResponse.status[0].status === 'BLOCKED' && validateUserResponse.status[0].year === 2024) {
        console.log('BL 2024');
        setMessage(
          <div
            role='alert'
            // className={styles.messageLabel}
            style={{ width: mobile ? 223 : tab ? 300 : '100%', color: '#be854c', ...styles.messageLabel }}
          >
            {`Uh-oh! Looks like you've unsubscribed from our mailing list. To access the event, please write to elevate@target.com.`}
          </div>
        );
        return;
      }

      // if we have the user data, no matter whats their status except rejected, we take them to form with pre populated data.

      if (validateUserResponse.member) {
        console.log('EXISTING USER ', validateUserResponse);
        dispatch(
          setExistingUserData({
            member: validateUserResponse.member,
            meta: validateUserResponse.meta,
            status: validateUserResponse.status,
          })
        );

        setOpen(false);
        navigate('/registeronspot');
        if (isMobile) {
          setopenSignUp(false);
        }
      } else {
        console.log('NEW USER ');
        // if we dont have the user data, we take them to new form.
        setOpen(false);
        // setopenSignUp(false);
        navigate('/registeronspot');
      }
    }
  }, [validateUserResponse]);

  const handleContinue = async (e) => {
    e.preventDefault();
    const lowerCaseMail = (emailId || '').toLowerCase();
    if (!IsEmail(lowerCaseMail)) {
      setError(true);
      emailRef.current.focus();
    } else {
      setError(false);
      setEmail(lowerCaseMail);
      try {
        // store the email id in Redux store
        dispatch(
          setEmailId({
            email_id: lowerCaseMail,
          })
        );
        // making an API call to know the details about the user by sending user entered email id.
        validateUser({
          eventId,
          email_id: {
            email: lowerCaseMail,
          },
        });
      } catch (error) {
        console.log('In catch block ', error);
      }
    }
  };

  return (
    <Grid>
      <Helmet>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-T7YMEDLNTX'></script>
        <script>
          {`  window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-T7YMEDLNTX');`}
        </script>
      </Helmet>
      {userSuccess ? (
        isheader || isAboutPage ? null : (
          <div style={{ display: 'flex' }}>{message} </div>
        )
      ) : isheader && !isAboutPage ? (
        <Link
          to='#'
          onClick={handleClickOpen}
          style={{ textDecoration: 'none', display: isMobile ? 'none' : 'block', color: 'rgb(102, 102, 102)', cursor: 'pointer' }}
        >
          <span
            // onClick={handleClickOpen}
            style={{
              // display: isMobile ? 'none' : 'block',
              // color: isheader && '#FFF',
              cursor: 'pointer',
            }}
          >
            {'Request an invitation'}
          </span>
        </Link>
      ) : (
        <button style={{ ...styles.customButton, display: isBanner || isRegisterPage ? 'none' : 'auto' }} type='button' onClick={handleClickOpen}>
          {'Request an invitation'}
        </button>
      )}
      <Dialog
        open={isMobile || isMentorship || isBanner || isRegisterPage ? openSignUp : open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        className={styles.dialog}
        aria-describedby='alert-dialog-slide-description'
        PaperProps={{
          style: { borderRadius: 25, width: '100%' },
        }}
        sx={{ background: isRegisterPage && 'rgba(0, 0, 0, 0.7)' }}
      >
        <div style={{ textAlign: 'right', padding: '10% 10% 0 0' }}>
          <button aria-label='close' onClick={() => handleClose()} style={styles.closeButton}></button>
        </div>
        <DialogContent style={{ padding: tab || mobile ? '0px 24px 70px' : '20px 52px 70px' }}>
          <form onSubmit={(e) => handleContinue(e)} id='Sigup Form'>
            <h1 style={{ fontSize: tab ? 35 : 35, ...styles.signUpText }}>Sign Up</h1>
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={styles.underLine} />
            </div> */}
            <div>
              <div style={styles.labelStyles}>
                {'Email'}
                <span aria-label='required'>*</span>
              </div>
              <TextField
                type={'text'}
                name={'emailId'}
                placeholder={'Enter your email here'}
                onChange={(e) => handleChange(e)}
                value={emailId}
                style={{ width: '100%' }}
                sx={styles.root}
                inputRef={emailRef}
                InputProps={{
                  inputProps: {
                    'aria-required': true,
                  },
                }}
              />
              {!isError ? null : <div style={styles.errorLabel}>{'Please enter a valid email'}</div>}
              {!message ? null : message}
            </div>
            <div style={{ marginTop: 16 }}>
              <button
                type='button'
                onClick={handleClose}
                style={{ padding: tab || mobile ? 14 : '12px 26px', background: '#666', marginRight: '20px', ...styles.customButton }}
              >
                Cancel
              </button>
              <button
                type='submit'
                disabled={message ? true : false}
                style={{ padding: tab || mobile ? 14 : '12px 26px', background: '#CC0000', ...styles.customButton }}
              >
                Continue
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  // const homeData = state.home;
  return {
    userSuccess: '',
    existingUser: '',
    isRedirect: '',
  };
};

const mapDispatchToProps = {
  //   storeUserDetails,
  //   storeEmailId,
  //   redirectToSignup,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpDialog);
