import React from 'react';
import { Grid } from '@mui/material';
import NavBar from '../Components/NavBar/NavBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

const Header = () => {
  const hideMenu = useSelector((state) => state.invenue.open)
  let theme = createTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  return hideMenu ? <></> : (
    (<Grid container style={{ height: desktop ? '35px' : '35px' }}>
      <Grid item md={12} sm={12} xs={12}>
        <NavBar />
      </Grid>
    </Grid>)
  );
};

export default Header;
