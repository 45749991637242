const galleryData = Object.freeze({
  2020: {
    images: [
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-0.jpg',
        alt: 'Target Elevate 2020 event lobby',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-1.jpg',
        alt: 'Target Elevate 2020 event lobby',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-2.jpg',
        alt: 'Event registration badges',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-3.jpg',
        alt: 'Event registration badges',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-4.jpg',
        alt: 'Target Elevate 2020 event venue',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-5.jpg',
        alt: 'Participants receive the Target Elevate 2020 Conference Kits',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-7.jpg',
        alt: 'Target Elevate volunteers and event delegates at the registration desk',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-8.jpg',
        alt: 'Target Elevate 2020 event lobby',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-10.jpg',
        alt: 'Mike McNamara, CIO, Target delivers the keynote address',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-11.jpg',
        alt: 'Farzana Haque speaks at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-12.jpg',
        alt: 'Audience at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-13.jpg',
        alt: 'Gayatri Jolly speaks at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-14.jpg',
        alt: 'Lakshmi Pratury speaks at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-15.jpg',
        alt: 'PC Musthafa speaks at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-16.jpg',
        alt: 'Audience at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-18.jpg',
        alt: 'Atul Jalan speaks at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-19.jpg',
        alt: 'Farhana Haque speaks at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-20.jpg',
        alt: 'Kamran Elahian speaks at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-21.jpg',
        alt: 'Conversations and networking at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-22.jpg',
        alt: 'Conversations and networking at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-23.jpg',
        alt: 'Rachel Whitcomb speaks at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-24.jpg',
        alt: 'Dr. Saundarya Rajesh speaks at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-25.jpg',
        alt: 'Dr. Gorur Srinivas of Target in India and Dr. Sujata Kelkar Shetty at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-26.jpg',
        alt: 'Shalini Sankarshana speaks at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-27.jpg',
        alt: 'One-on-one mentoring sessions',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-28.jpg',
        alt: 'One-on-one mentoring sessions',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-29.jpg',
        alt: 'Kiran Khalap, MD & Co-Founder, Chlorophyll delivers a workshop on building a personal brand.',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-30.jpg',
        alt: 'Target Elevate 2020 speaker sessions',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-31.jpg',
        alt: 'Lakshmi Pratury, CEO & Founder, INK Talks conducts a workshop on The Art of Storytelling',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-32.jpg',
        alt: 'Lakshmi Pratury, CEO & Founder, INK Talks conducts a workshop on The Art of Storytelling',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-33.jpg',
        alt: 'Naveen Kumar M.,  Director - Technology, Target in India delivers a workshop on the DevOps mindset',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-35.jpg',
        alt: 'Lunch-time at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-36.jpg',
        alt: 'Participants and speakers of Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-37.jpg',
        alt: 'Audience at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-38.jpg',
        alt: 'Hari Menon speaks at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-39.jpg',
        alt: 'Dr. Vishal Rao speaks at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-40.jpg',
        alt: 'Anuradha Madhusudan speaks at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-41.jpg',
        alt: 'Dr. Sujata Kelkar Shetty speaks at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-42.jpg',
        alt: 'Nivedha RM speaks at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-43.jpg',
        alt: 'YouTube stars Vidya Vox and Shankar Tucker speak and perform at Target Elevate 2020',
      },
      {
        src: 'https://gfc.target.com/elevatewebsiteproduction/9ed98e5c3e9685aa3de82c99009a2ed3-44.jpg',
        alt: 'YouTube stars Vidya Vox and Shankar Tucker speak and perform at Target Elevate 2020',
      },
    ],
    videos: [
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Tammy_Redpath.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Tammy-Redpath.mp4',
        title: 'Welcome',
        name: 'Tammy Redpath | SVP Marketing, Target Corporation',
        bio: 'Tammy sets the stage for a day of learning, conversations and networking at Target Elevate.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Tammy-Redpath.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Mike_Mcnamara.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Mike-McNamara.mp4',
        title: 'Keynote: Technology Transforms Businesses',
        name: 'Mike McNamara | CIO, Target Corporation',
        bio: 'Mike share insights on what to expect from the future of technology in an ever-changing landscape.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Mike-McNamara.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Farzana_Haque.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Farzana-Haque.mp4',
        title: 'Against All Odds',
        name: 'Farzana Haque | Business Director, Tata Consultancy Services (TCS)',
        bio: 'Hear Farzana talk about the importance of staying true to your core and balancing traditions with technology.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Farzana-Haque.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Gayatri_Jolly.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Gayatri-Jolly.mp4',
        title: 'Reinventing the Spindle',
        name: 'Gayatri Jolly | Founder, MasterG',
        bio: 'Gayatri shares her story about empowering women in low-income neighborhoods to earn a livelihood through fashion.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Gayatri-Jolly.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Lakshmi_Pratury.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Lakshmi-Pratury.mp4',
        title: 'The Power of Human Networking',
        name: 'Lakshmi Pratury | CEO & Founder, INK Talks',
        bio: 'Lakshmi drives home the importance of meaningful relationships and personal capital to build successful careers.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Lakshmi-Pratury.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/PC_Musthafa.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/PC-Musthafa.mp4',
        title: 'The Delicious Combination of Idli-Vada-Data',
        name: 'P.C. Musthafa | Founder, ID foods',
        bio: 'Learn the recipe behind the success of ID Foods, one of the first to revolutionize perishable foods through data & analytics.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/PC-Musthafa.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Adithi_Avasthi.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Aditi-Avasthi.mp4',
        title: 'Democratizing Education',
        name: 'Aditi Avasthi | Founder, Embibe',
        bio: 'Aditi’s AI-powered learning and scoring platform gives children across the country equal opportunity to learn and succeed.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Aditi-Avasthi.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Atul_Jatan.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Atul-Jalan.mp4',
        title: 'Awaiting Tomorrow',
        name: 'Atul Jalan | CEO, Algonomy',
        bio: 'Atul demonstrates that with a new code of ethics that is diverse and inclusive, Artificial Intelligence can transform life as we know it.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Atul-Jalan.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Farhana_Haque.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Farhana-Haque.mp4',
        title: 'Women in IoT',
        name: 'Farhana Haque | Group Director - Devices, Microsoft',
        bio: 'As a leading mind behind future tech trends, Farhana discusses disruption through IoT, now and forever.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Farhana-Haque.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Kumran_Elahian.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Kamran-Elahian.mp4',
        title: 'Tech for Peace',
        name: 'Kamran Elahian | Founder, Global Innovation',
        bio: 'Kamran talks about his work with tech entrepreneurs in high conflict zones, to give the youth a new lease on life, away from political crossfire.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Kamran-Elahian.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Rachel_Whitcomb.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Rachel-Whitcomb.mp4',
        title: 'We CAN Have It All',
        name: 'Rachel Whitcomb | VP - Engineering, Target Corporation',
        bio: 'Hear Rachel talk about how it really is possible for women to have it all – family, personal life and successful careers in tech.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Rachel-Whitcomb.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Soundaraya_Rajesh.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Dr-Saundarya-Rajesh.mp4',
        title: 'Get Intentional About Your Success',
        name: 'Dr. Saundarya Rajesh | CEO & Founder, Avtar',
        bio: 'Dr. Saundarya delves on the Intentional Career Pathing framework and tools to build a successful career as a technologist.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Dr-Saundarya-Rajesh.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Shalini_Sankarshana.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Shalini-Sankarshana.mp4',
        title: 'She’s a Fire Starter',
        name: 'Shalini Sankarshana | Senior EVP, Broadrigde',
        bio: 'Shalini’s built Herculean fintech solutions for Wall Street. Now, she’s building high-performance teams that adapt to change.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Shalini-Sankarshana.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Lakshmi_Pratury_Workshop.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Lakshmi-Pratury.mp4',
        title: 'The Business of Storytelling',
        name: 'Lakshmi Pratury | CEO & Founder, INK Talks',
        bio: 'Learn the tools you can use to understand the role of play in business scenarios, parallel thinking and intentional inhibition.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Lakshmi-workshop.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Kiran_Khalap.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Kiran-Workshop.mp4',
        title: 'What’s Your Personal Brand?',
        name: 'Kiran Khalap | MD & Co-Founder, Chlorophyll',
        bio: 'Kiran Khalap’s workshop is all about identifying and building Brand You and how you can communicate effectively through it.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Kiran-Workshop.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Naveen_KumarN.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Living-the-DevOps-Mindset.mp4',
        title: 'Living the DevOps Mindset',
        name: 'Naveen Kumar M | Director - Technology, Target in India',
        bio: 'Get hands-on with DevOps to help automate the processes between software development and IT teams.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Naveen%20Kumar%20M.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Anuradha_Madhushan.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Anuradha-Madhusudhan.mp4',
        title: 'All About the Guest',
        name: 'Anuradha Madhusudan | VP - Marketing, Target Corporation',
        bio: 'A look at 3D imaging at Target in India and perfecting cutting-edge technology to create a joyful digital experience.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/ Anuradha-Madhusudan.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Hari_Menon.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Hari-Menon.mp4',
        title: 'Building a Business on Trust and Technology',
        name: 'Hari Menon | Co-Founder & CEO, Big Basket',
        bio: 'Hear from the man behind Big Basket on how he made grocery shopping digital while retaining customer trust.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Hari-Menon.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/NivedhaR.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Nivedha-RM.mp4',
        title: 'Turning Trash to Treasure',
        name: 'Nivedha R.M. | Founder & CEO, TrashCon Labs Private Limited',
        bio: 'Discover how TrashCon recycles trash to impact lives, reduce carbon emissions and saves 50+ acres from becoming landfills.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Nivedha%20RM.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Dr.Sujatha–Kelkar–Shetty.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Dr-Sujata-Kelkar-Shetty.mp4',
        title: 'You Is Smart, You Is Kind, You Is Important',
        name: 'Dr. Sujata Kelkar Shetty | Writer & Wellness coach',
        bio: 'Dr. Sujata takes you on a journey of self-compassion and self-kindness in an increasingly busy world.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Dr-Sujata-Kelkar-Shetty.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/VidyaVox.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Vidya-Vox.mp4',
        title: 'On a Digital Note',
        name: 'Shankar Tucker | American Clarinetist Music Composer',
        name1: 'Vidya Vox | YouTuber & Singer',
        bio: 'Shankar Tucker and Vidya Vox speak of what the future of music looks like through a technology lens.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Vidya Vox.vtt',
      },
      {
        url: 'https://gfc.target.com/elevatewebsiteproduction/Dr.VishalRao.jpg',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Dr-Vishal-Rao.mp4',
        title: 'Are you a Possibilitarian?',
        name: 'Dr. Vishal Rao | Chief of Head & Neck Surgical Oncology & Robotic Surgery, HCG Cancer Center',
        bio: 'Dr. Vishal demonstrates the power of possibilities using the inspiring story of the 50 rupees voice box for cancer patients.',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Dr%20Vishal%20Rao.vtt',
      },
    ],
  },
  2022: {
    images: [],
    videos: [
      {
        title: 'Driving an innovation mindset',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Sonam_1.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Sonam_1[1].vtt',
        bio: 'Sonam Wangchuk shares his journey and approach towards driving impactful change through small, incremental innovations.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/sonam_wangchuk (1).png',
        name: 'Sonam Wangchuk',
        designation: 'Innovator and academician',
      },
      {
        title: 'Mindfulness in Leadership - 1',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Video 3 - Bhavna Toor (2).mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Bhavna Toor-Day 3.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Bhavna Toor-Day 3.vtt',
        bio: 'Bhavna Toor explores a few simple yet highly effective mindfulness-based techniques that can help you realize your leadership potential.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/bhavna_toor .png',
        name: 'Bhavna Toor',
        designation: 'Mindfulness Coach and Founder & CEO',
        contract_end_date: '',
      },
      {
        title: 'Humanizing Artificial Intelligence',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Video 3 - Pascal Bornet.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Video 3 - Pascal Bornet.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Video 3 - Pascal Bornet.vtt',
        bio: 'Pascal Bornet makes a case for how intelligent automation, powered by artificial intelligence, can create a better future for the human race.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/pascal_bornet.png',
        name: 'Pascal Bornet',
        designation: 'Intelligent automation expert, author and thought leader',
        contract_end_date: '',
      },
      {
        title: 'Success has no Timelines',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Sarah Travis_1.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Sarah Travis_1.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Sarah Travis_1.vtt',
        bio: 'Sarah Travis talks about how you can maximize opportunities at work and shape success on your own terms.          ',
        url: 'https://gfc.target.com/elevatewebsiteproduction/sara_travis.png',
        name: 'Sarah Travis',
        designation: 'President - Roundel, Media Reimagined by Target',
        contract_end_date: '',
      },
      {
        title: 'Mindfulness in Leadership - 2',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Video 4 - Bhavna Toor Session.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Video 4 - Bhavna Toor Session.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Video 4 - Bhavna Toor Session.vtt',
        bio: 'Bhavna Toor explores a few simple yet highly effective mindfulness-based techniques that can help you realize your leadership potential.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/bhavna_toor .png',
        name: 'Bhavna Toor',
        designation: 'Mindfulness Coach and Founder & CEO',
        contract_end_date: '',
      },
      {
        title: 'Leading without Limitations',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Apurva-Purohit-Elevate 2022-v1.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Apurva-Purohit-Elevate 2022-v1.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Apurva-Purohit-Elevate 2022-v1.vtt',
        bio: 'Apurva Purohit shares lessons on leadership that powered her success and can do the same for other women at the workplace.\n',
        url: 'https://gfc.target.com/elevatewebsiteproduction/apurva_purohit.png',
        name: 'Apurva Purohit',
        designation: 'Author and Co-founder',
        contract_end_date: '',
      },
      {
        title: 'Building Lasting Careers in Tech',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Video 4 - Mike and Nancy.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Video 4 - Mike and Nancy.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Video 4 - Mike and Nancy.vtt',
        bio: 'Mike McNamara and Nancy King draw on their personal experiences on how women can take charge of their careers and build long-lasting impact in the tech industry. ',
        url: 'https://gfc.target.com/elevatewebsiteproduction/mikeandnancy (1).png',
        name: 'Mike McNamara┃Nancy King',
        designation: 'EVP & CIO, Target┃SVP, Product Engineering, Target',
        contract_end_date: '',
      },
      {
        title: 'Breaking Barriers…Period',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Video 5 - Aditi Gupta.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Video 5 - Aditi Gupta.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Video 5 - Aditi Gupta.vtt',
        bio: 'Aditi Gupta talks about following her heart, overcoming societal challenges and staying committed to her goal of creating awareness on menstrual health among school students.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/aditi_gupta.png',
        name: 'Aditi Gupta',
        designation: 'Co-founder & Managing Partner',
        contract_end_date: '',
      },
      {
        title: 'The Power of Communities ',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Lathika Pai_1.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Lathika Pai_1.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Lathika Pai_1.vtt',
        bio: 'Lathika Pai discusses the importance of strengthening communities by engaging, inspiring and supporting women leaders on their professional journey.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/lathika_pai.png',
        name: 'Lathika Pai',
        designation: 'Country Head, VC & PE Partnerships',
        contract_end_date: '',
      },
    ],
  },
  2023: {
    images: [],
    videos: [
      {
        title: 'Target Elevate kick-off',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Brett_Video_Message_updated.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Brett_Video_Message_updated.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Brett_Video_Message_updated.vtt',
        bio: "Brett Craig kick's off Target Elevate 2023.",
        url: 'https://gfc.target.com/elevatewebsiteproduction/Brett-Craig.jpg',
        name: 'Brett Craig',
        designation: 'Executive Vice President & Chief Information Officer – Target',
      },
      {
        title: 'The courage of conviction',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Vaitheeswaran.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Vaitheeswaran.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Vaitheeswaran.vtt',
        bio: 'K Vaitheeswaran talks about displaying courage in dark times and finding the conviction to continue taking risks, venture into uncharted territory and emerge successful.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/K-Vaitheeswaran.jpg',
        name: 'K Vaitheeswaran',
        designation: 'Serial Entrepreneur, Author, Founder & CEO – Again Drinks',
      },
      // {
      //   title: 'Welcome and introduction to Target Elevate 2023',
      //   videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Bruce Starnes_Updated.mp4',
      //   captions: 'https://gfc.target.com/elevatewebsiteproduction/Bruce Starnes_Updated.vtt',
      //   transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Bruce Starnes_Updated.vtt',
      //   bio: 'Bruce Starnes welcomes the participants to Target Elevate 2023.',
      //   url: 'https://gfc.target.com/elevatewebsiteproduction/bruce.jpg',
      //   name: 'Bruce Starnes',
      //   designation: 'President – Target in India',
      // },
      {
        title: 'You’ve got one ride – make it count! ',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/JODIE_updated_video_message.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/JODIE_updated_video_message.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/JODIE_updated_video_message.vtt',
        bio: 'Jodie Kautt talks about the importance of investing in yourself and prioritizing things that matter to you and your career.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Jodie-Kautt.jpg',
        name: 'Jodie Kautt',
        designation: 'Vice President, Cybersecurity – Target Tech',
      },
      {
        title: 'The first happy steps to change',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Hasini Lakshminarayanan_Updated.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Hasini Lakshminarayanan_Updated.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Hasini Lakshminarayanan_Updated.vtt',
        bio: 'Hasini Lakshminarayanan is a Gen Z influencer, showing the way on how to break stereotypes and build resilience and confidence. She shares her own journey and talk about taking the first happy steps to embracing change.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Hasini-Lakshminarayanan.jpg',
        name: 'Hasini Lakshminarayanan',
        designation: 'Founder, The First Step',
      },
      {
        title: 'When resilience meets inspiration',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Lisa Ray.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Lisa Ray.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Lisa Ray.vtt',
        bio: 'Lisa Ray talks about her journey and finding inspiration and resilience in adversity.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Lisa-Ray.jpg',
        name: 'Lisa Ray',
        designation: 'Performer, Author, Co-founder, TheUpsideSpace',
      },
      {
        title: 'Tech for transformation, one schoolgirl at a time',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Aditi.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Aditi.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Aditi.vtt',
        bio: 'Aditi talks about using technology to drive lasting social change. An inspiring entrepreneur, her initiative, Indian Girls Code, empowers young girls, especially from rural India, to transform the world around them.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Aditi-Prasad.jpg',
        name: 'Aditi Prasad',
        designation: 'Co-Founder of Indian Girls Code, COO & CIO – Robotix',
      },
      {
        title: 'Navigating with purpose',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Cara_Video_Message_Updated.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Cara_Video_Message_Updated.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Cara_Video_Message_Updated.vtt',
        bio: 'Cara Sylvester talks about her unconventional career journey and the impact of conversations and connections.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Cara-Sylvester.jpg',
        name: 'Cara Sylvester',
        designation: 'Executive Vice President & Chief Guest Experience Officer – Target',
      },
      {
        title: 'The power of connected capabilities',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Prashanth, Godha & Neha.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Prashanth, Godha & Neha.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Prashanth, Godha & Neha.vtt',
        bio: 'Prashanth C, Godha Narayana, and Neha Dixit, talk about how Target’s platforms for 3D visualization, digital asset management and vision computing were custom-built and how these capabilities are being integrated to unlock new ideas and possibilities.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Prashant_Neha_Godha.jpg',
        name: 'Prashanth C',
        designation: 'Director, 3D & Imaging – Target in India ',
      },
      {
        title: 'The Creator Mindset approach to Design',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Harish.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Harish.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Harish.vtt',
        bio: 'An engineer and an artist, Harish Sivaramakrishnan has donned many hats. In this session, he talks about the importance of a creative mindset while approaching product design, at scale.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Harish-Sivaramakrishnan.jpg',
        name: 'Harish Sivaramakrishnan',
        designation: 'Engineer, Artist & Head of Design – CRED',
      },
      {
        title: 'Creating ‘antifragile’ systems in the next normal',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Jaspreet Bindra.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Jaspreet Bindra.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Jaspreet Bindra.vtt',
        bio: 'Disruption is a powerful catalyst to creating new advantages. Jaspreet Bindra explains how resilience is just the first step in leveraging emerging technologies to build anti-fragile ways of working. \nThis is an abridged version of his session from Target Elevate 2023.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Jaspreet-Bindra-.jpg',
        name: 'Jaspreet Bindra',
        designation: 'Author & Founder, Tech Whisperer',
      },
      {
        title: 'Workshop: Building a brand called YOU',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Karthik_1.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Karthik_1.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Karthik_1.vtt',
        bio: 'Creating a memorable presence on social media is almost a must-have for professional success. Karthik Srinivasan talks about creating your personal brand to enhance connections and making your social media profile stand out from the crowd. ',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Karthik-Srinivasan.jpg',
        name: 'Karthik Srinivasan',
        designation: 'Communications Strategy Consultant',
      },
      // {
      //   title: 'Welcome and introduction to Target Elevate 2023',
      //   videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Bruce Starnes_Updated.mp4',
      //   captions: 'https://gfc.target.com/elevatewebsiteproduction/Bruce Starnes_Updated.vtt',
      //   transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Bruce Starnes_Updated.vtt',
      //   bio: 'Bruce Starnes welcomes the participants to Target Elevate 2023.',
      //   url: 'https://gfc.target.com/elevatewebsiteproduction/bruce.jpg',
      //   name: 'Bruce Starnes',
      //   designation: 'President – Target in India',
      // },
      {
        title: 'How to become a powerful leader ',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Roopa and Sireesha.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Roopa and Sireesha.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Roopa and Sireesha.vtt',
        bio: 'Roopa and Sireesha, two powerful women leaders from Target in India, have charted very diverse career paths. Here, they share their learnings to success.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/RoopaSirisha.jpg',
        name: 'Roopa Acharya',
        designation: 'Vice President, Engineering – Target Tech',
      },
      {
        title: 'When art meets tech: Democratizing art through technology',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Kamini Sawhney_Updated.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Kamini Sawhney_Updated.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Kamini Sawhney_Updated.vtt',
        bio: 'Democratizing art through technology makes it not just more inclusive but also more relevant. Kamini Sawhney talks abou how this convergence is inspiring people to interact with art in ways that encourage humanity, empathy and a deeper understanding of the world we live in.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Kamini-Sawhney.jpg',
        name: 'Kamini Sawhney',
        designation: 'Director, Museum of Art & Photography (MAP)',
      },
      {
        title: 'Building technology for inclusion',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Divyanshu Ganatra_Updated.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Divyanshu Ganatra_Updated.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Divyanshu Ganatra_Updated.vtt',
        bio: 'Innovation and inclusion in technology has the power to change lives and help people re-imagine what is possible. Divyanshu Ganatra shares how we can build technology that is truly inclusive.\n',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Divyanshu.jpg',
        name: 'Divyanshu Ganatra',
        designation: 'Inclusion Advocate, Entrepreneur & Adventurer',
      },
    ],
  },
  2024: {
    images: [],
    videos: [
      {
        title: 'Welcome to Target Elevate',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Andrea_Zimmerman _No_Disclaimer.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Andrea_Zimmerman__No_Disclaimer.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Andrea_Zimmerman__No_Disclaimer.vtt',
        bio: 'Andrea Zimmerman welcomes the participants to Target Elevate 2024.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Andrea.jpg',
        name: 'Andrea Zimmerman | SVP & President - Target in India',
      },
      {
        title: 'Make authenticity your superpower',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Navneet_Kapoor_With_Disclaimer.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Navneet_Kapoor_With_Disclaimer.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Navneet_Kapoor_With_Disclaimer.vtt',
        bio: "In a world of conformity, Navneet Kapoor reveals the power of authenticity. Embracing one's true self is about being comfortable and vulnerable. According to him, just being true to yourself is key; there's no need to change as a person to succeed. Kapoor highlights the importance of authenticity in tech, empowering individuals to drive innovation with their unique identities.",
        url: 'https://gfc.target.com/elevatewebsiteproduction/Navneet Kapoor_Still image.jpg',
        name: 'Navneet Kapoor | EVP & CTIO - A.P. Moller - Maersk Host: Sunita Venkatachalam | Sr Director Tech - Target Tech',
      },
      {
        title: 'Maximize outcomes through collaboration',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Case_Study_1_Prat_Hari_Sultan_No_Disclaimer.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Case_Study_1_Prat_Hari_Sultan_No_Disclaimer.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Case_Study_1_Prat_Hari_Sultan_No_Disclaimer.vtt',
        bio: 'Collaboration is crucial for success in the evolving technology landscape. Harikrishnan Aravindakshan and Sultan Ahmed share their expertise on harnessing collective intelligence and breaking down silos for innovation. They emphasize diverse perspectives, noting how different viewpoints lead to more opportunities and magical outcomes.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Hari_Still_image.jpg',
        name: 'Harikrishnan Aravindakshan |Vice President, Product Engineering - Target & Sultan Ahmed | Sr Director, Product Management - Target',
      },
      {
        title: 'Inclusive triumphs & the rewards of resilience',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Alina_Alam_Mitti_Cafe_With_Disclaimer.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Alina_Alam_Mitti_Cafe_With_Disclaimer.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Alina_Alam_Mitti_Cafe_With_Disclaimer.vtt',
        bio: 'Celebrating diversity unveils resilience. Embracing different viewpoints sparks innovation. Alina Alam shares her journey of overcoming adversity, advocating dignity for those with disabilities. She emphasizes community support in realizing ideas, urging a shift in perceptions to uncover the brilliance of abilities.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Alina_Alam.jpg',
        name: 'Alina Alam | CEO - Mitti Café ',
      },
      {
        title: 'Catalyzing social change through technology',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Teena_paul_nidhi_panth_With Disclaimer.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Teena_paul__nidhi_panth_With_Disclaimer.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Teena_paul__nidhi_panth_With_Disclaimer.vtt',
        bio: 'Technology drives social change, empowering global communities. Teena Paul and Nidhi Pant discuss how it addresses societal challenges, nurturing a more equitable future. They stress the importance of serendipity, stepping out of comfort zones, focusing on solutions, and taking action.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Teena paul & nidhi panth.jpg',
        name: 'Teena Paul | Founder & CEO - Giftolexia Solutions & Nidhi Pant | Co-founder - S4S Technologies Host: Roopa Acharya | VP Engineering, Target Tech',
      },
      {
        title: 'Breaching the firewalls of fate: navigating a career in cybersecurity',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Nity_Singh_Concentrix_With_Disclaimer.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Nity_Singh_Concentrix_With_Disclaimer.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Nity_Singh_Concentrix_With_Disclaimer.vtt',
        bio: 'Navigating a career in cybersecurity involves mastering complex algorithms and combating evolving threats in a dynamic landscape. Nity Singh leans on the importance of resilience, ongoing learning, and ethical conduct as essential for success. She inspires individuals to excel, aspire to greatness, and contribute to the community, highlighting the impact of small acts of kindness.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Nity Singh.jpg',
        name: 'Nity Singh | Sr Director, Global Security - Concentrix Corporation',
      },
      {
        title: 'Dreaming big with tech',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Geetha_Manjunath_Niramai_With_Disclaimer.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Geetha_Manjunath_Niramai_With_Disclaimer.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Geetha_Manjunath_Niramai_With_Disclaimer.vtt',
        bio: 'Technology offers endless opportunities for those daring to pursue them. Dr. Geetha Manjunath explores the transformative power of audacious ideas, shaping our digital future. She encourages dreaming big, citing her founding of Niramai Health Analytix to combat breast cancer in India as an example of addressing societal problems through action.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Geetha Manjunath.jpg',
        name: 'Dr Geetha Manjunath | Founder, CEO & CTO - Niramai Health Analytix',
      },
      {
        title: 'Smart data for personalization',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Ashlee_Regan_Mudit_Ranjan_with_Disclaimer.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Ashlee_Regan_Mudit_Ranjan_with_Disclaimer.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Ashlee_Regan_Mudit_Ranjan_with_Disclaimer.vtt',
        bio: 'Smart data enables personalized experiences. Ashlee Regan and Mudit Ranjan discuss how Target leverages this for personalization, showcasing real-world examples of enhancing customer experiences and driving success. They highlight the benefits of empowering Target with a unified view of guest, bringing out mutual benefits.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Ashlee_Regan_Mudit_Ranjan.jpg',
        name: 'Ashlee Regan | Sr Director, Marketing Technology - Target & Mudit Ranjan Pati| Sr Director, Data Sciences - Target',
      },
      {
        title: 'Ignite your resolve in challenging roles',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Kate_Ash_Natarajan_Ramamurthy_No_Disclaimer.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Kate_Ash_-_Natarajan_Ramamurthy_No_Disclaimer.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Kate_Ash_-_Natarajan_Ramamurthy_No_Disclaimer.vtt',
        bio: 'In professional growth, navigating challenges sparks transformation. Kate Ash explores success strategies, highlighting how perseverance propels individuals towards achievements. She advises against comparing one’s journey to others’, as it can diminish confidence. ',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Kate Ash.jpg',
        name: 'Kate Ash | Sr Director, Infrastructure - Target Host: Natarajan Ramamurthy | VP Data Engineering - Target Tech',
      },
      {
        title: 'Learning leadership skills through theater',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Mahua_Sengupta_with_Disclaimer.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Mahua_Sengupta_with_Disclaimer.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Mahua_Sengupta_with_Disclaimer.vtt',
        bio: 'Theater offers a unique path to leadership development. Mohua Sengupta shares how immersive experiences refine communication, empathy, and adaptability. Drawing from her personal experience, she learnt the art of influencing through theater, advising against passion solely for milestones. ',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Mahua_Sengupta.jpg',
        name: 'Mohua Sengupta | Senior Business Leader',
      },
      {
        title: 'Powering a meaningful digital experience',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Case_Study_2_Purvi_Mangai_Arun_No_Disclaimer.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Case_Study_2_Purvi_Mangai_Arun_No_Disclaimer.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Case_Study_2_Purvi_Mangai_Arun_No_Disclaimer.vtt',
        bio: 'Crafting a truly meaningful digital experience demands empathy and innovative design. Presenting the work of Target’s Digital teams, Mangai Varadarajan and Arun Kalyanaraman explored strategies empowering businesses to prioritize user-centricity, facilitate impactful digital journeys. ',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Mangai.jpg',
        name: 'Mangai Varadarajan | Sr Director, Technology - Target  Arun Kalyanaraman | Sr Director, Technology – Target',
      },
      {
        title: 'How to find your calm in a storm',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Manish_Sharma_Printo_With_Disclaimer.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Manish_Sharma_Printo_With_Disclaimer.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Manish_Sharma_Printo_With_Disclaimer.vtt',
        bio: 'Amidst the rapid pace of a tech career, finding tranquility amidst chaos is crucial. Manish Sharma shares strategies for maintaining equilibrium, including the OWCH principle: Objective data, Worst-case scenario visualization, Core beliefs review, and Hacking a way-out. He advises stepping back, taking a deep breath, and asking, “Why am I doing this?”',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Manish Sharma.jpg',
        name: 'Manish Sharma | Co-Founder & CEO - Printo',
      },
      {
        title: 'Simplicity in a complex life',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Shwetambari_Shetty_With_Disclaimer.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Shwetambari_ShettyWith_Disclaimer.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Shwetambari_ShettyWith_Disclaimer.vtt',
        bio: 'In the fitness journey, simplicity is paramount. Shwetambari Shetty emphasizes the importance prioritizing health with whole foods, streamlined workouts, and mindfulness practices like yoga. She shares tips for simplifying our outlook towards well-being, highlighting how leading a simpler life leads to sustainable progress.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Shwetambari Shetty.jpg',
        name: 'Shwetambari Shetty | Fitness Expert - Cure.Fit',
      },
      {
        title: 'If I can, so can you: how your past can shape your future',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Priyanka_Gill_The_Good_Glam_With_Disclaimer.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Priyanka_Gill_The_Good_Glam_With_Disclaimer.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Priyanka_Gill_The_Good_Glam_With_Disclaimer.vtt',
        bio: 'Personal histories often profoundly impact future success. Drawing from her experience as an entrepreneur in beauty and media, Priyanka Gill emphasizes the power of resilience and determination. She shares her inspiring story of overcoming adversity and achieving her dreams, addressing women leaders to become the next role models.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Priyanka Gill.jpg',
        name: 'Priyanka Gill | Co-founder - Good Glamm Group, Host: Dr Gorur Shrinivas | VP, Engineering – Target Tech',
      },
      {
        title: 'Tomorrow, and Tomorrow, and Tomorrow',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Rachel_Whitcomb_No_Disclaimer.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Rachel_Whitcomb_No_Disclaimer.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Rachel_Whitcomb_No_Disclaimer.vtt',
        bio: "Rachel Whitcomb explores the significance of curiosity, creativity, and courage…and how these traits reconnect us to inner strength and expand our possibilities. Let's embrace curiosity, nurture creativity, and embody courage for the journey ahead. ",
        url: 'https://gfc.target.com/elevatewebsiteproduction/Rachel Whitcomb_Still image1.jpg',
        name: 'Rachel Whitcomb | SVP, Tech Capabilities – Target',
      },
      {
        title: 'Fierce, fearless, and fun: How to thrive in everything you do',
        videoUrl: 'https://gfc.target.com/elevatewebsiteproduction/Shraddha_Jain_With_Disclaimer.mp4',
        captions: 'https://gfc.target.com/elevatewebsiteproduction/Shraddha_Jain_With_Disclaimer.vtt',
        transcript_url: 'https://gfc.target.com/elevatewebsiteproduction/Shraddha_Jain_With_Disclaimer.vtt',
        bio: 'To thrive, one must practice fearlessness, resilience, and joy in both professional and personal pursuits. Shradha Jain, concluding Target Elevate 2024, recalls her journey as a techie and stresses the importance of trust and faith from immediate leaders. She shares insights on building a personal brand, infusing a blend of quirkiness and wisdom into her talk about embracing one’s inner spark.',
        url: 'https://gfc.target.com/elevatewebsiteproduction/Shraddha.jpg',
        name: 'Shraddha Jain | Comedian & Entertainer',
      },
    ],
  },
});
export { galleryData };
