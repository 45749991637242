import { createSlice } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiConfig from "../apiConfig";

const { api } = apiConfig;

const initialState = {
  eventId: "",
};

export const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {
    setEventId: (state, action) => {
      state.eventId = action.payload
        ? action.payload.eventId
        : initialState.eventId;
    },
  },
});

export const Tenantapi = createApi({
  reducerPath: "TenantApi",
  baseQuery: fetchBaseQuery({
    baseUrl: api.host,
  }),
  tagTypes: ["Tenant"],
  endpoints: (builder) => ({
    getActiveEvents: builder.query({
      query: ({ site_id }) => ({
        url: `/content/sites/${site_id}/events`,
        params: {
          key: api.key,
        },
      }),
      providesTags: ["ActiveEvents"],
    }),
  }),
});
// Action creators are generated for each case reducer function
export const { setEventId } = tenantSlice.actions;

export const { useGetActiveEventsQuery } = Tenantapi;

export default tenantSlice.reducer;
