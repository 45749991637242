import React, { useEffect, useState } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import MentorCard from "./MentorCard";
import {
  useGetActiveMentorshipsQuery,
  useBookMentorshipMutation,
  useCancelMentorshipMutation,
  useCancelInterestMutation,
  useShowInterestMutation,
} from "../../api/mentorship";
import { useSelector } from "react-redux";
import Login from "../Login/Login";

const Mentorship = () => {
  const loggedInUser = useSelector((state) => state.session.loggedInUser);
  const eventId = useSelector((state) => state.tenant.eventId);
  const [open, setOpen] = useState(false);
  const [successLogin, setLoginSuccess] = useState(false);
  const [mentorshipResponseData, setMentorshipResponseData] = useState([]);
  const [slotSelection, setSlotSelection] = useState("");
  const { isLoading: mentorshipLoading, data: mentorshipData } =
    useGetActiveMentorshipsQuery(
      { eventId },
      { skip: !loggedInUser || !eventId, refetchOnMountOrArgChange: true }
    );

  const [bookMentorship, { isLoading: bookingInProgress }] =
    useBookMentorshipMutation({}, { skip: !loggedInUser || !eventId });

  const [cancelMentorship, { isLoading: bookingCancelInProgress }] =
    useCancelMentorshipMutation({}, { skip: !loggedInUser || !eventId });

  const [cancelInterest, { isLoading: cancelInterestInProgress }] =
    useCancelInterestMutation({}, { skip: !loggedInUser || !eventId });

  const [showInterest, { isLoading: showInterestInProgress }] =
    useShowInterestMutation({}, { skip: !loggedInUser || !eventId });

  useEffect(() => {
    if (mentorshipData) {
      setMentorshipResponseData(mentorshipData.data);
    }
  }, [mentorshipData]);

  useEffect(() => {
    if (loggedInUser) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [loggedInUser]);

  return (
    <Box sx={{ mt: 12 }}>
      <Box sx={{ backgroundColor: "rgb(236, 236, 236)", px: 12, pb: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: 500, py: 2 }}>
          One-on-one mentoring at the conference
        </Typography>
        <Typography variant="body1" sx={{ fontSize: 15, my: 2 }}>
          Target Elevate 2023 offers you the opportunity to meet a mentor that
          you feel would be best suited to your aspiration and establish a
          mentor-mentee relationship that can continue beyond the conference. As
          a confirmed participant, you can book a mentorship slot in advance. If
          the mentor is available, you will receive confirmation on the site and
          via email for conversation with them at the conference.
        </Typography>
      </Box>
      {(mentorshipLoading ||
        bookingInProgress ||
        bookingCancelInProgress ||
        cancelInterestInProgress ||
        showInterestInProgress) && (
        <LinearProgress
          sx={{
            backgroundColor: "#be854c",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#aeaeae",
            },
          }}
        ></LinearProgress>
      )}
      {loggedInUser ? (
        <MentorCard
          mentorshipResponseData={mentorshipResponseData}
          bookMentorship={bookMentorship}
          cancelMentorship={cancelMentorship}
          cancelInterest={cancelInterest}
          showInterest={showInterest}
          setSlotSelection={setSlotSelection}
          slotSelection={slotSelection}
        ></MentorCard>
      ) : (
        <Login
          open={open}
          loggedInUser={loggedInUser}
          setOpen={setOpen}
          closeOption={false}
          setLoginSuccess={setLoginSuccess}
          successLogin={successLogin}
        ></Login>
      )}
    </Box>
  );
};

export default Mentorship;
