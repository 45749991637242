import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiConfig from "../apiConfig";

const { api } = apiConfig;

export const WorkshopApi = createApi({
  reducerPath: "WorkshopApi",
  baseQuery: fetchBaseQuery({
    baseUrl: api.host,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().session.authToken;
      if (token) {
        headers.set("authorization", token);
      }
      return headers;
    },
  }),
  tagTypes: ["Workshops"],
  endpoints: (builder) => ({
    getActiveWorkshops: builder.query({
      query: ({ eventId }) => ({
        url: `content/events/${eventId}/activeworkshops`,
        params: {
          key: api.key,
        },
      }),
      providesTags: ["Workshops"],
    }),
    bookWorkshop: builder.mutation({
      query: ({ eventId, workshopId }) => ({
        url: `content/events/${eventId}/workshops/${workshopId}/book`,
        method: "POST",
        params: {
          key: api.key,
        },
      }),
      invalidatesTags: ["Workshops"],
    }),
    cancelWorkshop: builder.mutation({
      query: ({ eventId, workshopId }) => ({
        url: `content/events/${eventId}/workshops/${workshopId}/cancel`,
        method: "POST",
        params: {
          key: api.key,
        },
      }),
      invalidatesTags: ["Workshops"],
    }),
    showInterest: builder.mutation({
      query: ({ eventId, workshopId }) => ({
        url: `content/events/${eventId}/workshops/${workshopId}/showinterest`,
        method: "POST",
        params: {
          key: api.key,
        },
      }),
      invalidatesTags: ["Workshops"],
    }),
    cancelInterest: builder.mutation({
      query: ({ eventId, workshopId }) => ({
        url: `content/events/${eventId}/workshops/${workshopId}/cancelinterest`,
        method: "POST",
        params: {
          key: api.key,
        },
      }),
      invalidatesTags: ["Workshops"],
    }),
  }),
});

export const {
  useGetActiveWorkshopsQuery,
  useBookWorkshopMutation,
  useCancelWorkshopMutation,
  useShowInterestMutation,
  useCancelInterestMutation,
} = WorkshopApi;
