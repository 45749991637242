import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiConfig from '../apiConfig';
import { createSlice } from '@reduxjs/toolkit';

const { api } = apiConfig;
const initialState = {
  email_id: '',
  isOpenSingUp: false,
  member: {},
  meta: {},
  status: [{}],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setEmailId: (state, action) => {
      state.email_id = action.payload ? action.payload.email_id : initialState.email_id;
    },
    setExistingUserData: (state, action) => {
      state.member = action.payload?.member;
      state.meta = action.payload?.meta;
      state.status = action.payload?.status;
    },
    setDialogStatus: (state, action) => {
      state.isOpenSingUp = action.payload?.isOpenSingUp;
    },
  },
});

export const ValidateUserApi = createApi({
  reducerPath: 'ValidateUserApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api.host,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().session.authToken;
      if (token) {
        headers.set('authorization', token);
      }
      return headers;
    },
  }),
  tagTypes: ['Validate'],
  endpoints: (builder) => ({
    ValidateUser: builder.mutation({
      query: ({ eventId, email_id }) => ({
        url: `content/events/${eventId}/participant/auth/validate`,
        method: 'POST',
        params: {
          key: api.key,
        },
        body: {
          ...email_id,
        },
      }),
      providesTags: ['Validate'],
    }),
  }),
});

export const { setEmailId, setExistingUserData, setDialogStatus } = userSlice.actions;

export const { useValidateUserMutation } = ValidateUserApi;
export default userSlice.reducer;
