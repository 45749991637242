import {
  Box,
  Button,
  Card,
  CardActionArea,
  Chip,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import _ from "lodash";
// import moment from "moment";
import { useBookMSMutation, useBookWSMutation } from "../../api/invenue";
import BookingConfirmationDialog from "./BookingConfirmationDialog";
import MentorshipBooking from "./MentorshipBooking";
import PrintCard from "./PrintCard";
import { logo } from "./../../assets/Images/Images";
import { Home } from "@mui/icons-material";

const RenderCard = (props) => {
  const {
    isSelected = false,
    setSelected,
    program,
    interest,
    recommended = "",
  } = props;
  const isFull =
    program?.booked_count && program?.booked_count >= program?.no_of_seats;
  const limitedSeats =
    !program?.restrictions?.allowed_participant_status.includes("ALL") &&
    program?.type !== "MENTORSHIP" &&
    !isFull;
  const isRecommended = recommended === program?._id;
  return (
    <Card
      elevation={0}
      sx={{
        overflow: "hidden",
        mr: {
          md: 2,
          sm: 1,
        },
        position: "relative",
        borderRadius: 2,
        p: 0.5,
        border: isSelected ? "1px solid #cc0000" : "1px solid #fff",
        "&::after":
          interest && !isFull
            ? {
                position: "absolute",
                content: '"You showed interest"',
                fontWeight: 700,
                fontSize: 14,
                top: 20,
                right: 0,
                height: 19,
                padding: 0.5,
                pl: 1,
                minWidth: "28%",
                background: isSelected ? "#fff" : "#cc0000",
                color: isSelected ? "#cc0000" : "white",
                textAlign: "left",
                boxShadow: "4px 4px 15px rgba(26, 35, 126, 0.2)",
                borderRadius: "5px 5px 0 5px",
                border: "1px solid",
              }
            : limitedSeats
            ? {
                position: "absolute",
                content: '"Limited seats"',
                fontWeight: 700,
                fontSize: 14,
                top: 20,
                right: 0,
                height: 19,
                padding: 0.5,
                pl: 1,
                minWidth: "28%",
                background: isSelected ? "#fff" : "#cc0000",
                color: isSelected ? "#cc0000" : "white",
                textAlign: "left",
                boxShadow: "4px 4px 15px rgba(26, 35, 126, 0.2)",
                borderRadius: "5px 5px 0 5px",
                border: "1px solid",
              }
            : isFull
            ? {
                position: "absolute",
                content: '"All slots booked"',
                fontWeight: 700,
                fontSize: 14,
                top: 20,
                right: 0,
                height: 19,
                padding: 0.5,
                pl: 1,
                minWidth: "28%",
                background: isSelected ? "#fff" : "#cc0000",
                color: isSelected ? "#cc0000" : "white",
                textAlign: "left",
                boxShadow: "4px 4px 15px rgba(26, 35, 126, 0.2)",
                borderRadius: "5px 5px 0 5px",
                border: "1px solid",
              }
            : {},
        "&::before": interest
          ? {
              position: "absolute",
              content: '""',
              background: isSelected ? "#210404" : "#820202",
              height: 27,
              width: 27,
              /* Added lines */
              top: 28,
              right: 0,
              transform: "rotate(45deg)",
            }
          : {},
      }}
    >
      <CardActionArea
        sx={{
          p: {
            md: 1,
            sm: 1,
          },
          pt: {
            md: 5,
            sm: 5,
          },
          minHeight: 380,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          background: isSelected
            ? "linear-gradient(0deg, rgba(215,12,15,1) 0%, rgba(173,15,15,1) 100%)"
            : isFull
            ? "linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(194,194,194,1) 100%)"
            : "linear-gradient(180deg, rgba(251,244,246,1) 0%, rgba(250,233,237,1) 100%)",
        }}
        disabled={isFull}
        onClick={() => setSelected(program)}
      >
        <Box
          sx={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Radio
            checked={isSelected}
            disabled={isFull}
            size="small"
            sx={{
              color: isSelected ? "#fff" : "#4d4d4d",
              "&.Mui-checked": {
                color: isSelected ? "#fff" : "#4d4d4d",
              },
            }}
          />
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                sm: 15,
              },
              fontWeight: "600",
              color: isSelected ? "#fff" : isFull ? "#8c8c8c" : "#5e5d5d",
            }}
          >
            {program?._secondary_name_text
              ? program?._secondary_name_text
              : program?.name}
          </Typography>
        </Box>
        <Box sx={{ p: 1 }}>
          <Box sx={{ minHeight: 10 }}>
            <Typography
              variant="h3"
              sx={{
                fontSize: {
                  md: 15,
                  sm: 15,
                },
                mb: 2,
                fontWeight: "bold !important",
                color: isSelected ? "#fff" : isFull ? "#8c8c8c" : "#cc0000",
              }}
            >
              {program?._secondary_name_text ? program?.name : ""}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: {
                  md: 13,
                  sm: 11,
                },
                color: isSelected ? "#fff" : isFull ? "#8c8c8c" : "#4d4d4d",
              }}
            >
              {program?.description}
            </Typography>
          </Box>
          {/* <Typography
            sx={{ fontSize: 13, mt: 1, color: isSelected ? "#fff" : "#4d4d4d" }}
          >
            <strong>Start : </strong>
            {moment(program?.start_time, "HHmm").format("LT")}
          </Typography>
          <Typography
            sx={{ fontSize: 13, color: isSelected ? "#fff" : "#4d4d4d" }}
          >
            <strong>End : </strong>
            {moment(program?.end_time, "HHmm").format("LT")}
          </Typography> */}
          {isRecommended && !isFull && (
            <Chip
              sx={{
                mt: 2,
                borderRadius: 0,
                position: "relative",
                background: isSelected ? "#fff" : "#cc0000",
                clipPath:
                  "polygon(0% 0%, 100% 0%, calc(100% - 15px) 50%, 100% 100%, 0% 100%)",
                color: isSelected ? "#cc0000" : "#fff",
                fontWeight: "700",
                borderLeft: "5px solid #8e0000",
                pr: 3,
                minHeight: 32,
              }}
              size="small"
              label="Recommended"
            ></Chip>
          )}
        </Box>
      </CardActionArea>
    </Card>
  );
};

const ParticipantBooking = (props) => {
  const {
    entry = {},
    eventId = null,
    auth,
    setCurrentEntry,
    setSearch,
    setUserId,
    setErrorDialog,
    setErrorData,
  } = props;
  const {
    user = {},
    workshops = [],
    mentorships = [],
    shown_interests = [],
    meta = {},
  } = entry;
  const { designation = "" } = meta;
  const [selected, setSelected] = React.useState(null);
  const [bookingInfo, setBookingInfo] = React.useState(null);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [secondStage, enableSecondStage] = React.useState(false);
  const [selectedSpeakers, setSelectedSpeakers] = React.useState([]);
  const [selectedSlots, setSelectedSlots] = React.useState([]);
  const [bookedSlotTimings, setBookedSlotTimings] = React.useState([]);
  const [displayData, setDisplayData] = React.useState({});
  const [programs, setPrograms] = React.useState({});
  const [recommended, setRecommended] = React.useState("");

  const [
    bookWS,
    {
      isLoading: wsBookingInProgress,
      data: wsBookingResponse,
      error: wsBookingError,
    },
  ] = useBookWSMutation();

  const [
    bookMS,
    {
      isLoading: msBookingInProgress,
      data: msBookingResponse,
      error: msBookingError,
    },
  ] = useBookMSMutation();

  const bookWorkshop = () => {
    bookWS({
      eventId,
      workship_id: selected?._id,
      member: user?._id,
      auth,
    });
  };

  const bookMentorship = () => {
    const payload = {
      eventId,
      mentorship_id: selected?._id,
      member: user?._id,
      auth,
      booking_info: [
        ...selectedSlots.map((slotinfo) => {
          const speaker = slotinfo.split(":")?.[0];
          const slot = slotinfo.split(":")?.[1];
          return { speaker, slot };
        }),
      ],
    };
    bookMS(payload);
  };

  const mentorshipSubSelect = () => {
    enableSecondStage(true);
  };

  const bookOrProceed = () => {
    if (selected) {
      switch (selected?.type) {
        case "MENTORSHIP":
          if (secondStage) {
            bookMentorship();
            setOpenConfirm(false);
          } else {
            mentorshipSubSelect();
            setOpenConfirm(false);
          }
          break;
        case "WORKSHOP":
          bookWorkshop();
          setOpenConfirm(false);
          break;
        default:
          break;
      }
    }
  };

  const clearAndGoBack = () => {
    setBookingInfo(null);
    setSelected(null);
    setCurrentEntry(null);
    setDisplayData({});
    setSearch("");
    setUserId("");
  };

  useEffect(() => {
    if (wsBookingResponse) {
      setBookingInfo({ ...selected, response: { ...wsBookingResponse } });
      setSelected(null);
      setTimeout(() => {
        setBookingInfo(null);
        setSelected(null);
        setCurrentEntry(null);
        setDisplayData({});
        setSearch("");
        setUserId("");
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsBookingResponse, setCurrentEntry, setSearch, setUserId]);

  useEffect(() => {
    if (msBookingResponse) {
      setBookingInfo({ ...selected, response: { ...msBookingResponse } });
      setSelected(null);
      setSelectedSlots([]);
      setBookedSlotTimings([]);
      setTimeout(() => {
        setBookingInfo(null);
        setSelected(null);
        setCurrentEntry(null);
        setDisplayData({});
        setSearch("");
        setUserId("");
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msBookingResponse, setCurrentEntry, setSearch, setUserId]);

  useEffect(() => {
    if (wsBookingError) {
      console.log(wsBookingError);
      setErrorDialog(true);
      setErrorData({
        data: {
          status: "BOOKING_ERROR",
        },
      });
    }
    if (msBookingError) {
      console.log(msBookingError);
      setErrorDialog(true);
      setErrorData({
        data: {
          status: "BOOKING_ERROR",
        },
      });
    }
  }, [wsBookingError, msBookingError, setErrorDialog, setErrorData]);

  const addRecommendation = (val, id, recommendationLocal) => {
    if (val === "*" && !recommendationLocal) {
      return id;
    }
    if (val && val !== "*") {
      const list = val.replaceAll(" ", "").split(",");
      const exist = list.filter((v) => {
        const k = new RegExp(v, "i");
        return k.test(designation);
      });
      if (exist && exist.length > 0) {
        return id;
      }
    }
    return recommendationLocal;
  };

  useEffect(() => {
    let programdata = {};
    if (mentorships.length > 0) {
      mentorships.map((m) => {
        programdata[m._id] = { name: m?.name, type: m?.type, ...m };
        return 0;
      });
    }
    let recommendationLocal = "";
    if (workshops.length > 0) {
      workshops.map((w) => {
        if (w?._recommended_text) {
          recommendationLocal = addRecommendation(
            w?._recommended_text,
            w?._id,
            recommendationLocal
          );
        }
        programdata[w._id] = { name: w?.name, type: w?.type, ...w };
        return 0;
      });
      setRecommended(recommendationLocal);
    }
    setPrograms({ ...programdata });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorships, workshops]);

  return (
    <Box
      sx={{
        textAlign: "left",
        height: { sm: "70vh" },
        p: {
          md: "2%",
          sm: "2%",
        },
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          textAlign: "left",
          mb: 2,
          display: "flex",
        }}
      >
        <img src={logo} alt="Target Elevate Logo" height="54" />
      </Box>
      {!bookingInfo && (
        <Box sx={{ display: "flex" }}>
          {!secondStage ? (
            <Box>
              <Typography variant="h3" sx={{ fontSize: 24, mb: 0.5 }}>
                Welcome <strong>{user?.name}</strong>
              </Typography>
              {/* <Typography
                variant="caption"
                sx={{ fontSize: 17, color: "#909090" }}
              >
                Please select the session we have curated specially for you
              </Typography> */}
            </Box>
          ) : (
            <Box>
              <Typography variant="h3" sx={{ fontSize: 24, mb: 0.5 }}>
                <strong>{mentorships?.[0]?._secondary_name_text}</strong>
              </Typography>
              <Typography
                variant="caption"
                sx={{ fontSize: 17, color: "#909090" }}
              >
                Choose any 2 mentors from below and click on ‘Confirm’ to book
                your slot.
              </Typography>
            </Box>
          )}
          <Box sx={{ flexGrow: 1 }}></Box>
        </Box>
      )}
      <Paper
        elevation={0}
        sx={{
          // p: 3,
          mt: 5,
          background: "transparent",
          overflow: "auto",
          position: "relative",
        }}
      >
        {bookingInfo ? (
          <Grid container>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="h3" sx={{ fontSize: 24, mb: 0.5 }}>
                Congratualtions
                <br />
                <strong>{user?.name}</strong>
                <br />
                Your booking has been confirmed. Please pick up your badge and
                get ready for inspiration!
                <br />
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    mt: 1,
                    background:
                      "linear-gradient(90deg, rgba(215,12,15,1) 0%, rgba(173,15,15,1) 100%)",
                  }}
                  startIcon={<Home />}
                  onClick={clearAndGoBack}
                >
                  Back to Home
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <PrintCard
                  user={user}
                  booking={bookingInfo}
                  programs={programs}
                />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <>
            {wsBookingInProgress ||
              (msBookingInProgress && (
                <LinearProgress
                  color={"error"}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: 7,
                  }}
                />
              ))}
            {!secondStage ? (
              <Grid container>
                {mentorships &&
                  mentorships.length > 0 &&
                  mentorships.map((ms) => {
                    const isSelected = ms?._id === selected?._id;
                    const interest = _.find(shown_interests, {
                      program: ms?._id,
                    });
                    return (
                      <Grid item xs={12} sm={3} key={ms?._id}>
                        <RenderCard
                          program={ms}
                          setSelected={setSelected}
                          isSelected={isSelected}
                          interest={interest}
                          disabled={wsBookingInProgress}
                        />
                      </Grid>
                    );
                  })}
                {workshops &&
                  workshops.length > 0 &&
                  _.sortBy(workshops, ["_priority_text"], ["desc"]).map(
                    (ws) => {
                      const isSelected = ws?._id === selected?._id;
                      const interest = _.find(shown_interests, {
                        program: ws?._id,
                      });
                      return (
                        <Grid item xs={12} sm={3} key={ws?._id}>
                          <RenderCard
                            program={ws}
                            setSelected={setSelected}
                            isSelected={isSelected}
                            interest={interest}
                            disabled={wsBookingInProgress}
                            recommended={recommended}
                          />
                        </Grid>
                      );
                    }
                  )}
              </Grid>
            ) : (
              <Paper
                sx={{
                  height: "54vh",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    "-webkit-appearance": " none",
                    width: 10,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: 5,
                    backgroundColor: "rgba(0,0,0,.5)",
                    "-webkit-box-shadow": "0 0 1px rgba(255,255,255,.5)",
                  },
                }}
              >
                <MentorshipBooking
                  mentorships={mentorships}
                  selectedSlots={selectedSlots}
                  selectedSpeakers={selectedSpeakers}
                  setSelectedSlots={setSelectedSlots}
                  setSelectedSpeakers={setSelectedSpeakers}
                  bookedSlotTimings={bookedSlotTimings}
                  setBookedSlotTimings={setBookedSlotTimings}
                  displayData={displayData}
                  setDisplayData={setDisplayData}
                  interests={shown_interests}
                />
              </Paper>
            )}
          </>
        )}
      </Paper>
      {!bookingInfo && (
        <Box
          sx={{
            position: "absolute",
            bottom: "3%",
            right: "3%",
            left: "3%",
            display: "flex",
            width: "90%",
            justifyContent: "flex-end",
            mt: 1,
            p: "2%",
            alignItems: "center",
          }}
        >
          {Object.keys(displayData).length > 0 && (
            <Box
              sx={{
                display: "flex",
                mr: 1,
                position: "absolute",
                left: { md: "3%", sm: "3%" },
                bottom: { md: "33%", sm: 150 },
                alignItems: "center",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: 16, mr: 1 }}>
                  Selected slots
                </Typography>
              </Box>
              {Object.keys(displayData).map((speakerId) => {
                const selected = displayData?.[speakerId];
                return (
                  <Chip
                    size={"medium"}
                    sx={{ mr: 1, fontWeight: "bold" }}
                    label={`${selected?.name} - ${selected?.slot_data}`}
                  />
                );
              })}
            </Box>
          )}
          <Button
            sx={{
              textTransform: "none",
              color: "#cc0000",
              borderRadius: 30,
              borderColor: "#cc0000",
              minWidth: 130,
              mr: 1,
            }}
            variant="outlined"
            onClick={clearAndGoBack}
          >
            Go Back
          </Button>
          <Button
            sx={{
              textTransform: "none",
              color: "#fff",
              borderRadius: 30,
              background:
                "linear-gradient(90deg, rgba(215,12,15,1) 0%, rgba(173,15,15,1) 100%)",
              "&:hover": {
                color: "#fff",
                background:
                  "linear-gradient(90deg, rgba(215,12,15,1) 0%, rgba(173,15,15,1) 100%)",
              },
              "&.Mui-disabled": {
                background: "#f3f3f3",
                color: "#939393!important",
              },
              minWidth: 130,
            }}
            variant="contained"
            disabled={
              !selected ||
              wsBookingInProgress ||
              msBookingInProgress ||
              (selected?.type === "MENTORSHIP" &&
                secondStage &&
                selectedSlots.length <= 1)
            }
            onClick={() => {
              if (selected?.type === "MENTORSHIP") {
                if (secondStage) {
                  // setOpenConfirm(true);
                  bookOrProceed();
                } else {
                  mentorshipSubSelect();
                }
              } else {
                // setOpenConfirm(true);
                bookOrProceed();
              }
            }}
          >
            {selected?.type === "MENTORSHIP" && !secondStage
              ? "Proceed"
              : "Confirm"}
          </Button>
        </Box>
      )}
      <BookingConfirmationDialog
        open={openConfirm}
        handleConfirm={bookOrProceed}
        handleClose={() => setOpenConfirm(false)}
        program={selected}
        slots={bookedSlotTimings}
      />
    </Box>
  );
};

export default ParticipantBooking;
