/* eslint-disable no-self-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  IsValidDesignation,
  IsValidFirstName,
  IsValidCompanyName,
  IsValidLastName,
  IsValidLinkedInProfile,
  IsValidMobileNo,
  IsValidBio,
  IsValidCity,
} from '../../Utils/utils';
import { states, years, advertisementChannels } from './Details';
import Popup from './Popup';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import HeaderBanner from '../../Utils/HeaderBanner';
import { headerBannerBg } from '../../assets/Images/Images';
import { useRegisterUserMutation, useUpdateYtrUserMutation } from '../../api/registration';
import { setEmailId, setExistingUserData } from '../../api/validateUser';
import customstyles from './Registration.module.css';
import SignUpDialog from './SignUpDialog';
const styles = {
  radioButton: {
    color: '#7f7f7f !important',
    '&.Mui-checked': {
      color: '#cc0000 !important',
    },
  },
  container: {
    background: 'white',
    zIndex: 1,
    // backgroundImage: `url(${registerBg})`,
    backgroundSize: '100% auto',
    backgroundRepeat: 'no-repeat',
    margin: '0 -17%',
  },
  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
  },
  register: {
    // fontFamily: 'HelveticaforTarget-Light',
    fontSize: 58,
    // margin: '30px 0 12px 0',
    fontWeight: 100,
    color: '#333333',
  },
  underLine: {
    border: '2px solid #be854c',
    width: 120,
    marginLeft: 5,
  },
  modalBody: {
    // fontFamily: 'HelveticaforTargetBold',
    width: '100%',
    padding: '30px 0 22px 18px',
    color: '#666666',
    fontSize: '25px',
  },
  labelStyles: {
    fontSize: 16,
    // fontFamily: 'HelveticaforTarget',
    marginBottom: 4,
    marginLeft: 18,
    fontWeight: 500,
  },
  consentLabelStyles: {
    fontSize: 16,
    // fontFamily: 'HelveticaforTarget',
    // marginBottom: 4,
    // marginLeft: 18,
    fontWeight: 500,
  },
  areasOfInterest: {
    fontSize: 16,
    // fontFamily: 'HelveticaforTargetRegular',
    marginBottom: 4,
    marginLeft: 18,
    color: '#6d6d6d',
  },
  // inputStyles: {
  //   border: '2px solid #be854c',
  //   borderRadius: 50,
  //   fontSize: 14,
  //   width: 330,
  //   padding: '14px 20px',
  //   fontFamily: 'HelveticaforTargetRegular',
  //   marginBottom: 4,
  //   '&:focus': {
  //     outline: '2px dotted #666666',
  //     outlineOffset: 2,
  //   },
  //   '&:disabled': {
  //     backgroundColor: '#e9ecef',
  //     opacity: 1,
  //   },
  //   '&::placeholder': {
  //     color: '#777777',
  //     fontFamily: 'HelveticaforTargetRegular',
  //   },
  // },
  errorLabel: {
    // fontFamily: 'HelveticaforTargetBold',
    marginLeft: 18,
    color: '#e92e1a',
    fontSize: 14,
    marginBottom: 6,
  },
  customButton: {
    fontSize: 14,
    padding: '12px 26px',
    marginBottom: 11,
    borderRadius: 50,
    background: '#333333',
    color: '#fff',
    minWidth: 125,
    // fontFamily: 'HelveticaforTarget',
    fontWeight: 500,
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      background: '#333333',
      boxShadow: 'none',
    },
    '&:focus': {
      background: '#d61b32',
      outline: '2px dotted #FFF',
      outlineOffset: 2,
    },
  },
  root: {
    '& .MuiOutlinedInput-root': {
      width: 330,
      height: 50,
      fontSize: 14,
      // fontFamily: 'HelveticaforTargetRegular',
      marginBottom: 4,
      '& fieldset': {
        borderRadius: `50px`,
        border: '2px solid #e6e6e6',
        fontSize: 14,
      },
    },
    '& .Mui-focused': {
      borderRadius: `50px`,
      outline: '2px dotted #666666',
      outlineOffset: 2,
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#7f7f7f !important',
      },
    },
    '& .MuiOutlinedInput-input': {
      color: '#666666',
      textIndent: 9,
      // fontFamily: 'HelveticaforTargetRegular',
    },
  },
  prefix: {
    position: 'relative',
    left: 16,
    top: '-38.5px',
    width: 0,
    // fontFamily: 'HelveticaforTargetRegular',
    fontSize: 14,
  },
  prefixNumber: {
    display: 'none',
    position: 'absolute',
    top: '1px',
  },
};

const InputField = ({
  label = '',
  name = '',
  type = 'text',
  required = false,
  placeholder = '',
  value = '',
  handleChange,
  styles,
  errorLabel = '',
  disabled = false,
  isError = '',
  inputRef,
}) => {
  return (
    <Grid item lg={6} md={12} sm={12}>
      {!label ? (
        <div style={{ marginTop: 10 }} />
      ) : (
        <label for={name} style={styles.labelStyles}>
          {label}
          {name === 'linkedInProfile' ? null : <span aria-hidden='true'>*</span>}
        </label>
      )}
      <input
        style={{ display: 'block', marginTop: '4px' }}
        type={type}
        name={name}
        placeholder={placeholder}
        ref={inputRef}
        // required={required}
        onChange={handleChange}
        value={value}
        // style={styles.inputStyles}
        className={customstyles['inputStyles']}
        disabled={disabled}
        aria-required={required}
        aria-label={label}
        aria-describedby={label.replace(/ /g, '')}
      />
      {name === 'linkedInProfile' ? (
        value || isError ? (
          <div id={label.replace(/ /g, '')} style={styles.errorLabel}>
            {isError}
          </div>
        ) : null
      ) : !isError ? (
        <div style={{ marginBottom: 22 }} />
      ) : (
        <div id={label.replace(/ /g, '')} style={styles.errorLabel}>
          {isError}
        </div>
      )}
    </Grid>
  );
};

const MobileNoInputField = ({
  label = '',
  name = '',
  type = 'text',
  required = false,
  placeholder = '',
  value = '',
  handleChange,
  styles,
  errorLabel = '',
  disabled = false,
  isError = '',
  inputRef,
}) => {
  const [isNumber, setNumber] = useState(value ? true : false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (value) {
      setNumber(true);
    }
  }, []);

  return (
    <Grid item lg={6} md={12} sm={12}>
      {!label ? (
        <div style={{ marginTop: 10 }} />
      ) : (
        <div style={styles.labelStyles}>
          {label} <span aria-hidden='true'>*</span>
        </div>
      )}
      <input
        type={type}
        name={name}
        placeholder={isNumber ? '' : placeholder}
        ref={inputRef}
        // required={required}
        onChange={handleChange}
        value={value}
        // className={styles.inputStyles}
        disabled={disabled}
        aria-required={required}
        className={customstyles['inputStyles']}
        style={{ display: 'block', textIndent: isNumber ? '22px' : 0 }}
        onKeyPress={() => setNumber(true)}
      />
      <div style={styles.prefix}>
        <div style={{ ...styles.prefixNumber, display: isNumber ? 'block' : 'none' }}>+91</div>
      </div>
      {!isError ? <div style={{ marginBottom: isNumber ? 0 : 22 }} /> : <div style={styles.errorLabel}>{isError}</div>}
    </Grid>
  );
};

const SelectField = ({
  label = '',
  name = '',
  value = '',
  handleChange,
  styles,
  errorLabel = '',
  options = [],
  isError = false,
  required = false,
  inputRef = {},
}) => {
  return (
    <Grid item lg={6} md={12} sm={12}>
      {!label ? (
        <div style={{ marginTop: 10 }} />
      ) : (
        <label for={name} style={styles.labelStyles}>
          {label}
          <span aria-hidden='true'>*</span>
        </label>
      )}

      <TextField
        id={name}
        name={name}
        select
        value={value}
        // required={required}
        onChange={handleChange}
        SelectProps={{
          native: true,
        }}
        // styles={{ root: styles.root }}
        sx={{ color: !value ? 'grey' : '', fontSize: 14, ...styles.root, display: 'block' }}
        inputRef={inputRef}
      >
        {options.map((item) => (
          <option key={item.value} value={item.value} style={{ fontSize: 14 }}>
            {item.label}
          </option>
        ))}
      </TextField>
      {value || !isError ? <div style={{ marginBottom: 22 }} /> : <div style={styles.errorLabel}>{errorLabel}</div>}
    </Grid>
  );
};

const Registration = ({ user, existingUser, storeUserDetails, storeRegisterSuccess, storeEmailId, storeExistingUser }) => {
  const eventId = useSelector((state) => state.tenant.eventId);

  const dispatch = useDispatch();
  const existingUserData = useSelector((state) => {
    return {
      ...state.user.meta,
      ...state.user.member,
      ...state.user.status,
    };
  });

  // eslint-disable-next-line no-unused-vars
  const [registerUser, { data: registerUserResponse, isLoading: registerUserResLoading, error: registerUserResError }] = useRegisterUserMutation();
  // eslint-disable-next-line no-unused-vars
  const [updateYtrUser, { data: updateYtrUserResponse, isLoading: updateYtrUserResLoading, error: updateYtrUserResError }] = useUpdateYtrUserMutation();
  const emailId = useSelector((state) => state.user.email_id);
  const navigate = useNavigate();
  const mobile = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const [open, setOpen] = useState(false);
  const [openRegDialog, setOpenRegDialog] = useState(false);
  const [searchParams] = useSearchParams();
  const [socialUTM, setSocialUTM] = useState();
  const [isAdmin, setIsAdmin] = useState(false);

  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    mobileNo: '',
    email: '',
    houseNo: '',
    additionalDetails: '',
    landmark: '',
    city: '',
    state: '',
    pincode: '',
    optedForKit: false,
    companyName: '',
    designation: '',
    experience: '',
    linkedInProfile: '',
    describeYourRole: '',
    advertisementChannel: '',
    isError: false,
    approved: false,
    registrationSuccess: false,
    isConsent: '',
  });

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const mobileNoRef = useRef();
  // const houseNoRef = useRef();
  // const additionalDetailsRef = useRef();
  // const landmarkRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const pincodeRef = useRef();
  const companyNameRef = useRef();
  const designationRef = useRef();
  const experienceRef = useRef();
  const linkedInProfileRef = useRef();
  const describeYourRoleRef = useRef();
  const areasOfInterestRef = useRef();
  const advertisementChannelRef = useRef();
  const consentOptionRef = useRef();

  const [errors, setFieldErrors] = useState({
    firstNameError: '',
    lastNameError: '',
    houseNoError: '',
    additionalDetailsError: '',
    landmarkError: '',
    cityError: '',
    pincodeError: '',
    companyNameError: '',
    designationError: '',
    linkedInProfileError: '',
    describeYourRoleError: '',
    mobileNoError: '',
    consentError: '',
  });

  useEffect(() => {
    if (registerUserResponse?.status) {
      console.log('registerUserResponse ', registerUserResponse);
      setOpen(true);
      dispatch(
        setExistingUserData({
          member: {},
          meta: {},
          status: [],
        })
      );
    }
    if (registerUserResError) {
      console.log('error ', registerUserResError);
    }
  }, [registerUserResponse]);

  const [areas, setAreas] = useState({
    'Emerging Tech': [
      {
        value: 'Metaverse',
        label: 'Metaverse',
        checked: false,
      },
      {
        value: 'IoT',
        label: 'IoT',
        checked: false,
      },
      {
        value: 'Sustainable Tech',
        label: 'Sustainable Tech',
        checked: false,
      },
    ],
    'Information Security': [
      {
        value: 'Security Engineering',
        label: 'Security Engineering',
        checked: false,
      },
      {
        value: 'Data & Privacy',
        label: 'Data & Privacy',
        checked: false,
      },
      {
        value: 'Ethical Hacking',
        label: 'Ethical Hacking',
        checked: false,
      },
    ],
    'Data Engineering and Sciences': [
      {
        value: 'AI/ML',
        label: 'AI/ML',
        checked: false,
      },
      {
        value: 'Data Pipelines',
        label: 'Data Pipelines',
        checked: false,
      },
    ],
    'Product Management': [
      {
        value: 'Product Ownership',
        label: 'Product Ownership',
        checked: false,
      },
      {
        value: 'Product Leadership',
        label: 'Product Leadership',
        checked: false,
      },
    ],

    'Software Engineering': [
      {
        value: 'WebDevelopment(UI, API)',
        label: 'Web Development (UI, API)',
        checked: false,
      },
      {
        value: 'Mobile',
        label: 'Mobile',
        checked: false,
      },
      {
        value: 'Package',
        label: 'Package',
        checked: false,
      },
      {
        value: 'Chatbots',
        label: 'Chatbots',
        checked: false,
      },
      {
        value: 'Database',
        label: 'Database',
        checked: false,
      },
    ],
    Infrastructure: [
      {
        value: 'Cloud',
        label: 'Cloud',
        checked: false,
      },
      {
        value: 'Platforms',
        label: 'Platforms',
        checked: false,
      },
      {
        value: 'SRE',
        label: 'SRE',
        checked: false,
      },
    ],
  });

  useEffect(() => {
    if (searchParams.get('utm_source')) {
      setSocialUTM(searchParams.get('utm_source'));
    }
    if (searchParams.get('isAdmin')) {
      setIsAdmin(true);
    }
    if (emailId) {
      setOpenRegDialog(false);
    } else {
      setOpenRegDialog(true);
    }
    // if (!emailId) {
    //   navigate('/');
    //   return;
    // }
    console.log('existing user data registraion page ', existingUserData, existingUserData[0]?.year);
    const tempObj = {};
    if (existingUserData.member && !(existingUserData[0] && existingUserData[0]?.status === 'REJECTED' && existingUserData[0]?.year !== 2024)) {
      const userName = existingUserData['name'].split(' ');
      tempObj['first_name'] = userName[0];
      tempObj['last_name'] = userName[1];
      tempObj['organisation'] = existingUserData['company'];
      tempObj['contact_no'] = existingUserData['mobile'];
      tempObj['designation'] = existingUserData['designation'];
      tempObj['exp_years'] = existingUserData['experience'];
      tempObj['linkedin_profile_url'] = existingUserData['linked_in_profile'];
      tempObj['about_user'] = existingUserData['about_user'];
      tempObj['advertisement_channel'] = existingUserData['advertisement_channel'];
      tempObj['areasOfInterest'] = existingUserData['areas_of_interest'];
      tempObj['areas_of_interest_level2'] = existingUserData['areas_of_interest_level2'];
      tempObj['city'] = existingUserData['city'];
      tempObj['state'] = existingUserData['state'];
    }

    const {
      first_name = '',
      last_name = '',
      contact_no = '',
      opted_for_kit = false,
      organisation = '',
      designation = '',
      exp_years = '',
      linkedin_profile_url = '',
      about_user = '',
      advertisement_channel = '',
      areasOfInterest = '',
      areas_of_interest_level2 = '',
      registration_success = false,
      approved = false,
      city = '',
      state = '',
      isConsent = '',
    } = tempObj;
    //areaTitle == areasOfInterest
    if (areasOfInterest) {
      const newAreasofInterest = { ...areas };
      // setIsArea([{ ...isArea }, ...areas_of_interest_level2.split(';')]);
      newAreasofInterest[areasOfInterest].map((area) => {
        areas_of_interest_level2.split(';').map((subArea) => {
          if (area['value'] === subArea) {
            area['checked'] = true;
          }
        });
      });

      setAreas(newAreasofInterest);
      setSelectedAreaTitle(areasOfInterest);
      setIsArea(_.filter(newAreasofInterest[areasOfInterest], { checked: true }));
    }

    setUserData({
      firstName: first_name,
      lastName: last_name,
      mobileNo: contact_no,
      email: emailId,
      optedForKit: opted_for_kit,
      companyName: organisation,
      designation: designation,
      experience: exp_years,
      linkedInProfile: linkedin_profile_url,
      describeYourRole: about_user,
      advertisementChannel: advertisement_channel,
      approved: approved,
      registrationSuccess: registration_success,
      city: city,
      state: state,
      isConsent: isConsent,
    });
  }, [user, emailId]);

  // let isArea = 0; //areas.filter((value) => value.checked === true);
  const [isArea, setIsArea] = useState(0);

  const handleChange = (e) => {
    const name = e.target.name;
    if (name === 'isConsent') {
      setUserData({
        ...userData,
        [name]: e.target.value,
      });
    }
    if (name === 'mobileNo') {
      let value = e.target.value.replace(/\D/g, '');
      if (value.length > 10) {
        value = value.slice(0, 10);
      } else {
        value = value;
      }
      setUserData({
        ...userData,
        [name]: value,
      });
    } else if (name === 'pincode') {
      let value = e.target.value.replace(/\D/g, '');
      if (value.length > 6) {
        value = value.slice(0, 6);
      } else {
        value = value;
      }
      setUserData({
        ...userData,
        [name]: value,
      });
    } else if (name === 'describeYourRole') {
      let value = e.target.value;
      const no_words = value.split(' ');
      if (value.length > 15) {
        value = value.slice(0, 15);
      } else {
        value = value;
      }
      setUserData({
        ...userData,
        [name]: value,
      });
      if (no_words.length <= 50) {
        setFieldErrors({
          ...errors,
          describeYourRoleError: '',
        });
      } else {
        setFieldErrors({
          ...errors,
          describeYourRoleError: 'Should be 50 words or less',
        });
      }
    } else if (name === 'houseNo' || name === 'additionalDetails') {
      const value = e.target.value.replace(/[^a-zA-Z0-9 .,-]/gi, '');
      setUserData({
        ...userData,
        [name]: value,
      });
    } else {
      const value = e.target.value;
      setUserData({
        ...userData,
        [name]: value,
      });
    }
  };

  const [selectedAreaTitle, setSelectedAreaTitle] = useState('');

  const handleCheck = (e, index, areaTitle) => {
    const checked = e.target.checked;
    const newAreas = { ...areas };
    newAreas[areaTitle][index].checked = checked;

    setAreas(newAreas);
    setSelectedAreaTitle(areaTitle);
    setIsArea(_.filter(areas[areaTitle], { checked: true }));
  };

  const validations = () => {
    const {
      firstName,
      mobileNo,
      lastName,
      // houseNo,
      // additionalDetails,
      // landmark,
      // pincode,
      city,
      companyName,
      designation,
      describeYourRole,
      linkedInProfile,
      isConsent,
    } = userData;

    const newErrors = { ...errors };
    if (!isConsent) {
      newErrors.consentError = 'Please choose your consent';
    } else {
      newErrors.consentError = '';
    }
    if (!firstName) {
      newErrors.firstNameError = 'Please provide your first name';
    } else if (!IsValidFirstName(firstName)) {
      newErrors.firstNameError = 'Please provide a valid first name';
    } else {
      newErrors.firstNameError = '';
    }
    if (!lastName) {
      newErrors.lastNameError = 'Please provide your last name';
    } else if (!IsValidLastName(lastName)) {
      newErrors.lastNameError = 'Please provide a valid last name';
    } else {
      newErrors.lastNameError = '';
    }
    if (!mobileNo) {
      newErrors.mobileNoError = 'Please provide your mobile number';
    } else if (!IsValidMobileNo(mobileNo)) {
      newErrors.mobileNoError = 'Please provide a valid mobile number';
    } else {
      newErrors.mobileNoError = '';
    }

    if (!describeYourRole) {
      newErrors.describeYourRoleError = 'Please describe your role';
    } else if (!IsValidBio(describeYourRole)) {
      newErrors.describeYourRoleError = 'Please provide valid details';
    } else if (describeYourRole.length > 15) {
      newErrors.describeYourRoleError = 'Max 15 characters';
    } else {
      newErrors.describeYourRoleError = '';
    }
    if (!city) {
      newErrors.cityError = 'Please provide your city';
    } else if (!IsValidCity(city)) {
      newErrors.cityError = 'Please provide a valid city';
    } else {
      newErrors.cityError = '';
    }

    if (!companyName) {
      newErrors.companyNameError = 'Please provide your company name';
    } else if (!IsValidCompanyName(companyName)) {
      newErrors.companyNameError = 'Please provide a valid company name';
    } else {
      newErrors.companyNameError = '';
    }
    if (!designation) {
      newErrors.designationError = 'Please provide your designation';
    } else if (!IsValidDesignation(designation)) {
      newErrors.designationError = 'Please provide a valid designation';
    } else {
      newErrors.designationError = '';
    }
    if (linkedInProfile && !IsValidLinkedInProfile(linkedInProfile)) {
      newErrors.linkedInProfileError = 'Please provide a valid linkedIn profile';
    } else {
      newErrors.linkedInProfileError = '';
    }
    setFieldErrors(newErrors);
    const isError = Object.values(newErrors).some((k) => k !== '');
    newErrors.firstNameError
      ? firstNameRef.current.focus()
      : newErrors.lastNameError
      ? lastNameRef.current.focus()
      : newErrors.mobileNoError
      ? mobileNoRef.current.focus()
      : // : newErrors.houseNoError
      // ? houseNoRef.current.focus()
      // : newErrors.additionalDetailsError
      // ? additionalDetailsRef.current.focus()
      // : newErrors.landmarkError
      // ? landmarkRef.current.focus()
      newErrors.cityError
      ? cityRef.current.focus()
      : !userData.state
      ? stateRef.current.focus()
      : newErrors.pincodeError
      ? pincodeRef.current.focus()
      : newErrors.companyNameError
      ? companyNameRef.current.focus()
      : newErrors.designationError
      ? designationRef.current.focus()
      : !userData.experience
      ? experienceRef.current.focus()
      : newErrors.linkedInProfileError
      ? linkedInProfileRef.current.focus()
      : newErrors.describeYourRoleError
      ? describeYourRoleRef.current.focus()
      : newErrors.consentError
      ? consentOptionRef.current.focus()
      : isArea.length === 0
      ? areasOfInterestRef.current.focus()
      : !userData.advertisementChannel && advertisementChannelRef.current.focus();

    return isError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      firstName = '',
      lastName = '',
      mobileNo = '',
      optedForKit = false,
      city = '',
      state = '',
      companyName = '',
      designation = '',
      email = '',
      experience = '',
      describeYourRole = '',
      linkedInProfile = '',
      advertisementChannel = '',
      approved = false,
      registrationSuccess = false,
      isConsent = '',
    } = userData;

    if (validations() || !state || !experience || !advertisementChannel) {
      setUserData({
        ...userData,
        isError: true,
      });
    } else {
      setUserData({
        ...userData,
        isError: false,
      });
      let areasOfInterest = isArea && isArea.filter((value) => value.checked === true).map((value) => value.value);
      let modifiedAreasOfInterest = areasOfInterest && areasOfInterest?.join(';'); // pass this to areas_of_interest_level2
      // eslint-disable-next-line no-unused-vars
      let myObj = {
        email: (email || '').toLowerCase().trim(),
        name: (firstName || '').trim() + ' ' + (lastName || '').trim(),
        mobile: (mobileNo || '').trim(),
        designation: (designation || '').trim(),
        company: (companyName || '').trim(),
        about_user: (describeYourRole || '').trim(),
        experience: experience,
        areas_of_interest: selectedAreaTitle,
        areas_of_interest_level2: modifiedAreasOfInterest,
        advertisement_channel: advertisementChannel,
        linked_in_profile: (linkedInProfile || '').trim(),
        opted_for_kit: optedForKit,
        city: (city || '').trim(),
        state: state,
        approved: approved,
        registration_success: approved ? true : registrationSuccess,
        isconsent: isConsent,
        social: socialUTM ? socialUTM : '',
        registered_in_venue: isAdmin,
      };

      try {
        if (existingUserData && existingUserData.member) {
          myObj['id'] = existingUserData['id'];
          updateYtrUser({
            eventId,
            userDataToUpdate: { ...myObj },
          });
        } else {
          registerUser({
            eventId,
            registerData: { ...myObj },
          });
        }
        setOpen(true);
      } catch (error) {
        console.log('error ', error);
        const { response = {} } = error;
        const { data = {} } = response;
        if (data) {
          const {
            aboutUser = '',
            addressLine1 = '',
            addressLine2 = '',
            city = '',
            contactNo = '',
            designation = '',
            firstName = '',
            landmark = '',
            lastName = '',
            organisation = '',
            pincode = '',
            linkedInProfileUrl = '',
          } = data;

          setFieldErrors({
            firstNameError: firstName ? firstName : '',
            lastNameError: lastName ? lastName : '',
            houseNoError: addressLine1 ? addressLine1 : '',
            additionalDetailsError: addressLine2 ? addressLine2 : '',
            landmarkError: landmark ? landmark : '',
            cityError: city ? city : '',
            pincodeError: pincode ? pincode : '',
            companyNameError: organisation ? organisation : '',
            designationError: designation ? designation : '',
            linkedInProfileError: linkedInProfileUrl ? linkedInProfileUrl : '',
            describeYourRoleError: aboutUser ? aboutUser : '',
            mobileNoError: contactNo ? contactNo : '',
          });
        }
      }
    }
  };

  const handleSingupClose = () => {
    setOpenRegDialog(false);
    navigate('/');
    if (isAdmin) {
      navigate('/adminpanel');
    }
  };
  const handleClose = () => {
    setOpen(false);

    dispatch(
      setExistingUserData({
        member: {},
        meta: {},
        status: [],
      })
    );
    dispatch(
      setEmailId({
        email_id: '',
      })
    );
    if (isAdmin) {
      navigate('/adminpanel');
    } else {
      navigate('/');
    }
  };

  const containerStyles = !mobile ? { padding: '20px 15px 15px' } : { width: '75%', margin: '0 auto', paddingTop: '20px' };

  return (
    <>
      <Helmet>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <title>Target Elevate - Register</title>
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-T7YMEDLNTX'></script>
        <script>
          {`  window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-T7YMEDLNTX');`}
        </script>
        <script type='text/javascript'>
          {`_linkedin_partner_id = "3079162";
                        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                        window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
        </script>
        <script type='text/javascript'>
          {`(function(l) {
                            if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                            window.lintrk.q=[]}
                            var s = document.getElementsByTagName("script")[0];
                            var b = document.createElement("script");
                            b.type = "text/javascript";b.async = true;
                            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                            s.parentNode.insertBefore(b, s);})(window.lintrk);
                    `}
        </script>
        <noscript>{`<img height='1' width='1' style='display:none;' alt='' src='https://px.ads.linkedin.com/collect/?pid=3079162&fmt=gif' />`}</noscript>
      </Helmet>
      <HeaderBanner marginTop={mobile ? '35px' : '75px'} headerTxt={'register'} headerBannerBg={headerBannerBg} />
      <Grid style={containerStyles}>
        <Popup open={open} handleClose={handleClose} existingUser={existingUser} />
        <SignUpDialog isRegisterPage={true} openSignUp={openRegDialog} setopenSignUp={setOpenRegDialog} handleSingUpClose={handleSingupClose} />
        <form onSubmit={handleSubmit} id='registrationForm' aria-label='elevate registration form'>
          <fieldset style={{ border: 'none', paddingLeft: 0, marginLeft: 0 }}>
            {/* <legend className={styles.modalBody} style={!mobile ? { padding: 0 } : { padding: '30px 0 22px' }}>
                            Tell us about yourself
                        </legend> */}
            <Grid container>
              <InputField
                label='First Name'
                type='text'
                name='firstName'
                styles={styles}
                isError={errors.firstNameError}
                value={userData.firstName}
                handleChange={handleChange}
                placeholder='Enter your first name'
                errorLabel={'Please provide your valid first name'}
                inputRef={firstNameRef}
                required={true}
              />
              <InputField
                label='Last Name'
                type='text'
                name='lastName'
                value={userData.lastName}
                styles={styles}
                isError={errors.lastNameError}
                handleChange={handleChange}
                placeholder='Enter your last name'
                errorLabel={'Please provide your valid last name'}
                inputRef={lastNameRef}
                required={true}
              />
            </Grid>
            <Grid container>
              <MobileNoInputField
                label='Mobile'
                type='text'
                name='mobileNo'
                value={userData.mobileNo}
                styles={styles}
                isError={errors.mobileNoError}
                handleChange={handleChange}
                placeholder='Enter your mobile number'
                errorLabel={'Please provide your valid mobile number'}
                inputRef={mobileNoRef}
                required={true}
              />
              <InputField
                aria-required='true'
                label='Email address'
                type='text'
                name='email'
                value={userData.email}
                styles={styles}
                handleChange={handleChange}
                disabled={true}
              />
            </Grid>
            <Grid container>
              <InputField
                label='City'
                type='text'
                name='city'
                value={userData.city}
                styles={styles}
                isError={errors.cityError}
                handleChange={handleChange}
                placeholder='City'
                errorLabel='Please provide your valid City'
                inputRef={cityRef}
                required={true}
              />
              <SelectField
                style={{ display: 'flex', flexDirection: 'column' }}
                options={states}
                name='state'
                id='state'
                value={userData.state}
                styles={styles}
                isError={userData.isError}
                handleChange={handleChange}
                errorLabel='Please provide your state'
                inputRef={stateRef}
                required={true}
                label='State'
              />
            </Grid>
          </fieldset>
          <fieldset style={{ border: 'none', paddingLeft: 0, marginLeft: 0 }}>
            <legend style={!mobile ? { padding: 0, ...styles.modalBody } : { padding: '30px 0 22px', ...styles.modalBody }}>Where do you work?</legend>
            <Grid container>
              <InputField
                label='Company'
                type='text'
                name='companyName'
                value={userData.companyName}
                styles={styles}
                isError={errors.companyNameError}
                handleChange={handleChange}
                placeholder='Enter your company name'
                errorLabel='Please provide your valid company name'
                inputRef={companyNameRef}
                required={true}
              />
              <InputField
                label='Designation'
                type='text'
                name='designation'
                value={userData.designation}
                styles={styles}
                isError={errors.designationError}
                handleChange={handleChange}
                placeholder='Enter your designation'
                errorLabel='Please provide your valid designation'
                required={true}
                inputRef={designationRef}
              />
            </Grid>
            <Grid container>
              <Grid item lg={6} md={12} sm={12}>
                <SelectField
                  sx={{ marginTop: '4px' }}
                  label='Total years of experience'
                  options={years}
                  name='experience'
                  value={userData.experience}
                  styles={styles}
                  isError={userData.isError}
                  handleChange={handleChange}
                  errorLabel='Please provide years of experience'
                  required={true}
                  inputRef={experienceRef}
                />
                <InputField
                  label='LinkedIn Profile'
                  type='text'
                  name='linkedInProfile'
                  required={false}
                  value={userData.linkedInProfile}
                  styles={styles}
                  isError={errors.linkedInProfileError}
                  handleChange={handleChange}
                  errorLabel={'Please provide your valid linkedIn profile'}
                  placeholder='Paste your profile url here'
                  inputRef={linkedInProfileRef}
                />
              </Grid>
              <Grid item lg={6} md={12} sm={12}>
                <div style={styles.labelStyles}>
                  {'Domain'}
                  <span aria-hidden='true'>*</span>
                </div>
                <textarea
                  name='describeYourRole'
                  placeholder='Ex: Retail, Finance etc (Max 15 characters)'
                  rows='4'
                  onChange={handleChange}
                  value={userData.describeYourRole}
                  //   className={styles.inputStyles}
                  className={customstyles['inputStyles']}
                  style={{ borderRadius: 25, resize: 'none' }}
                  ref={describeYourRoleRef}
                  aria-label={'Domain Example Retail, Finance etc (Max 15 characters)'}
                  aria-required={true}
                />
                {!errors.describeYourRoleError ? null : <div style={styles.errorLabel}>{errors.describeYourRoleError}</div>}
              </Grid>
            </Grid>
          </fieldset>

          <fieldset style={{ border: 'none', paddingLeft: 0, marginLeft: 0 }}>
            <span style={!mobile ? { padding: 0, ...styles.modalBody } : { padding: '30px 0 22px', ...styles.modalBody }}>Just a few more things</span>

            <Grid>
              <fieldset style={{ border: 'none', paddingLeft: 0, marginLeft: 0 }}>
                <legend style={styles.labelStyles}>
                  {'Area of interest (pick one primary area)'}
                  <span aria-hidden='true'>*</span>
                </legend>
                <Grid container style={{ marginLeft: '18px' }}>
                  {areas &&
                    Object.keys(areas).map((areaTitle, index) => (
                      <Grid item md={6} sm={12} xs={12}>
                        <fieldset style={{ border: 'none', paddingLeft: 0, marginLeft: 0 }}>
                          <legend style={styles.labelStyless}> {areaTitle}</legend>

                          <br />
                          {areas[areaTitle].map((area, index) => {
                            return (
                              <Grid item lg={12} md={12} sm={12} xs={12} key={area.value} style={{ marginBottom: 16 }}>
                                <Checkbox
                                  sx={{
                                    color: '#7f7f7f',
                                    padding: 0,
                                    '&.Mui-checked': {
                                      color: '#CC0000',
                                    },
                                    '&.Mui-focusVisible': {
                                      // boxShadow: '0 0 0 0.25rem #be854c57',
                                      outline: '2px dotted #666666',
                                      outlineOffset: 2,
                                      borderColor: '#be854c57',
                                      borderRadius: 0,
                                    },
                                  }}
                                  name='areasOfInterest'
                                  id={area.value}
                                  onClick={(e) => handleCheck(e, index, areaTitle)}
                                  checked={area.checked}
                                  inputRef={areasOfInterestRef}
                                  disabled={selectedAreaTitle !== '' && areaTitle !== selectedAreaTitle && isArea.length > 0}
                                />

                                <label style={{ marginLeft: 9, ...styles.labelStyles }} htmlFor={area.value}>
                                  {area.label}
                                </label>
                              </Grid>
                            );
                          })}
                        </fieldset>
                      </Grid>
                    ))}
                </Grid>
              </fieldset>

              {isArea.length > 0 || !userData.isError ? (
                <div style={{ marginBottom: 22 }} />
              ) : (
                <div style={styles.errorLabel}>{'Please select atleast one area of interest'}</div>
              )}
            </Grid>

            <SelectField
              label={'How did you hear about Target Elevate?'}
              style={{ display: 'flex', flexDirection: 'column' }}
              options={advertisementChannels}
              name='advertisementChannel'
              value={userData.advertisementChannel}
              styles={styles}
              isError={userData.isError}
              handleChange={handleChange}
              errorLabel='Please select one of the options listed above'
              required={true}
              inputRef={advertisementChannelRef}
            />
          </fieldset>
          <Grid item md={12} sm={12} style={{ maxWidth: '100%', padding: '15px 0' }}>
            <div style={styles.consentLabelStyles}>
              <p>
                Please visit{' '}
                <a
                  href='https://elevate.target.com/Elevate_Consent_Form.pdf'
                  target='_blank'
                  rel='noreferrer'
                  aria-label='this will open consent form for elevate in new tab'
                >
                  here
                </a>{' '}
                to read the consent form and then click on "I understand and give my consent" below.
                <span aria-hidden='true'>*</span>
              </p>
              <p>You do not need to do anything (sign, etc.) within the consent form document that opens in a separate tab.</p>
              {/* {
                      'Please visit      <a style={{ color: '#FFF' }} href='https://multiplai.target.com/Consent_Form.pdf' target='_blank' rel='noreferrer'>
                        here
                      </a> to read the consent form and then click on "I understand and give my consent" below.'
                    } */}
            </div>
            <RadioGroup aria-labelledby='demo-radio-buttons-group-label' defaultValue='agreed' name='isConsent' onChange={handleChange}>
              <Grid container>
                <Grid item md={12} sm={12}>
                  <FormControlLabel
                    style={{ width: '100%' }}
                    sx={styles.consentLabelStyles}
                    value='agreed for consent'
                    control={<Radio ref={consentOptionRef} sx={styles.radioButton} />}
                    label='I understand the above and give my consent'
                  />
                </Grid>
                <Grid item md={12} sm={12}>
                  <FormControlLabel
                    sx={styles.consentLabelStyles}
                    value='not agreed for consent'
                    control={<Radio ref={consentOptionRef} sx={styles.radioButton} />}
                    label='I do not consent (if you choose this option, your response will be collected'
                  />
                </Grid>
              </Grid>
            </RadioGroup>
            {!errors.consentError ? null : <div style={styles.errorLabel}>{errors.consentError}</div>}
          </Grid>
          <Grid item lg={12} md={12} sm={12} style={{ textAlign: 'center' }}>
            <button className={customstyles['customButton']} type='submit'>
              Confirm
            </button>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  // const homeData = state.home;

  return {
    user: '',
    emailId: '',
    existingUser: '',
  };
};

export default connect(mapStateToProps)(Registration);
