/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import cx from 'classnames';
import useMediaQuery from '@mui/material/useMediaQuery';
import { withStyles } from '@mui/styles';
import { Grid, IconButton, Modal } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import whiteCrossSvg from '../../assets/Images/white_cross.svg';

const styles = (theme) => ({
  container: {
    columnCount: 5,
    columnGap: 0,
  },
  imageContainer: {
    position: 'relative',
    margin: '0px 1px 2px',
    display: 'flex',
    background: '#ffffff',
    '&:hover ': {
      opacity: 0.8,
      '& $expandIcon': {
        display: 'block !important',
        zIndex: '100',
      },
    },
    border: 'none',
    padding: 0,
    '&:focus-visible': {
      opacity: 0.8,
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
  },
  hello: {
    color: 'red',
  },

  expandIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: 'auto',
    display: 'none !important',
    color: '#FFF',
    '&:hover ': {
      display: 'block !important',
      zIndex: '200',
    },
  },
  image: {
    width: '100%',
    cursor: 'pointer',
  },
  lightBoxWrapper: {
    height: '100%',
    backgroundColor: '#000000',
    boxShadow: theme.shadows[5],
    padding: 0,
    textAlign: 'left',
    position: 'relative',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  imageBox: {
    height: '100vh',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#484848',
    display: 'flex',
    alignItems: 'center',
  },
  showImage: {
    justifyContent: 'space-between',
  },
  arrowButton: {
    color: '#FFFFFF',
    background: 'rgba(0, 0, 0, 0.5)',
    backgroundImage: 'none',
    height: '60px',
    width: '60px',
    margin: '10px',
    fontSize: '36px',
  },
  imageBoxHeader: {
    height: '40px',
    backgroundColor: '#000000',
    fontFamily: 'HelveticaforTargetRegular',
    fontSize: 14,
    fontWeight: 'normal',
    color: '#e5e5e5',
    padding: 10,
    '&:focus': {
      outline: 'none',
    },
  },
  mouseOut: {
    display: 'none',
  },
  mouseIn: {
    display: 'block',
  },
  closeIcon: {
    background: `url(${whiteCrossSvg}) no-repeat`,
    float: 'right',
    right: '2%',
    top: '10px',
    position: 'absolute',
    fontSize: '40px !important',
    cursor: 'pointer',
    width: '20px',
    height: '20px',
    border: 'none',
    [theme.breakpoints.down('md')]: {
      width: '13px',
    },
  },
});

const RenderImages = ({ classes, galleryImages }) => {
  const [images, setImages] = useState([]);
  const [isModalOpen, setModel] = useState(false);
  const [url, setURL] = useState('');
  const [altText, setAltText] = useState('');
  const [index, setIndex] = useState();

  useEffect(() => {
    setImages(_.shuffle(galleryImages));
  }, []);

  const handleClick = (image, i) => {
    setIndex(i);
    setURL(image.src);
    setAltText(image.alt);
    setModel(true);
  };

  const prevAsset = () => {
    if (index === 0) {
      const image = images[images.length - 1];
      setURL(image.src);
      setAltText(image.alt);
      setIndex(images.length - 1);
      return;
    }
    const i = index - 1;
    const image = images[i];
    setURL(image.src);
    setAltText(image.alt);
    setIndex(i);
  };

  const nextAsset = () => {
    if (index === images.length - 1) {
      const image = images[0];
      setURL(image.src);
      setAltText(image.alt);
      setIndex(0);
      return;
    }
    const i = index + 1;
    const image = images[i];
    setURL(image.src);
    setAltText(image.alt);
    setIndex(i);
  };

  useEffect(() => {
    const onArrowKeyPress = (event) => {
      const leftArrow = 37;
      const rightArrow = 39;

      if (event.keyCode === leftArrow) {
        prevAsset();
      } else if (event.keyCode === rightArrow) {
        nextAsset();
      }
    };
    document.addEventListener('keydown', onArrowKeyPress);
    return () => document.removeEventListener('keydown', onArrowKeyPress);
  }, [nextAsset, prevAsset]);

  const mobile = useMediaQuery('(max-width:755px)');
  const tab = useMediaQuery('(max-width:1008px)');
  const desktop = useMediaQuery('(max-width:1494px)');

  return (
    <div
      container={'true'}
      className={classes.container}
      style={{ columnCount: mobile ? 2 : tab ? 3 : desktop ? 4 : 5, paddingBottom: 2 }}
      role='dialog'
      aria-label='images of 2020 elevate slideshow'
    >
      {!isModalOpen &&
        images.map((image, i) => {
          return (
            <button aria-label={image.alt} key={image.src} className={classes.imageContainer} onClick={() => handleClick(image, i)}>
              <img src={image.src} alt={image.alt} className={classes.image} />
              <SearchIcon className={classes.expandIcon} fontSize='large' />
            </button>
          );
        })}

      <Modal open={isModalOpen} onClose={() => setModel(false)} role='dialog' aria-label='images of 2020 elevate slideshow'>
        <div>
          <Grid item className={classes.imageBoxHeader}>
            <div style={{ display: 'flex', width: 200, justifyContent: 'space-between' }}>
              <div>{`${index + 1}/${images.length}`}</div>
              <h2 style={{ fontSize: '13px', margin: 0 }}>{'Target Elevate'}</h2>
            </div>
            {/* <Close onClick={() => setModel(false)} fontSize='large' style={{ cursor: 'pointer', position: 'absolute', top: 8, right: 2 }} /> */}
            <button className={classes.closeIcon} onClick={() => setModel(false)} aria-label='close'></button>
          </Grid>
          <Grid container className={classes.lightBoxWrapper}>
            <Grid
              className={cx(classes.imageBox, classes.showImage)}
              style={{
                backgroundImage: `url(${url})`,
                backgroundColor: '#000000',
              }}
              item
              xs={12}
              aria-label={altText}
            >
              <IconButton aria-label='previous image' className={classes.arrowButton} style={{ color: 'white', fontSize: 50 }} onClick={() => prevAsset()}>
                <ChevronLeft fontSize='inherit' />
              </IconButton>
              <IconButton aria-label='next image' className={classes.arrowButton} style={{ color: 'white', fontSize: 50 }} onClick={() => nextAsset()}>
                <ChevronRight fontSize='inherit' />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

export default withStyles(styles)(RenderImages);
