import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import moment from "moment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const getSessionTimings = (timeData) => {
  if (timeData) {
    let slotValues = timeData.split("-");
    let startValue = moment(slotValues[0], ["h:mm"]).toDate();
    let startTime = moment(startValue).format("hh:mm A");
    let endValue = moment(slotValues[1], ["h:mm"]).toDate();
    let endTime = moment(endValue).format("hh:mm A");

    return startTime + "-" + endTime;
  }
};

export const BookingConfirmationDialog = (props) => {
  const { open, handleConfirm, handleClose, program, slots = [] } = props;
  const isMentorship = program?.type === "MENTORSHIP";
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Please confirm your booking
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            <strong>Session: </strong>
            {program?.name}
          </Typography>
          {isMentorship ? (
            <>
              {slots &&
                slots.length > 0 &&
                slots.map((slot, key) => {
                  return (
                    <React.Fragment key={slot}>
                      <Typography sx={{ fontSize: 13 }}>
                        <strong>Slot {key + 1}: </strong>{" "}
                        {getSessionTimings(slot)}
                      </Typography>
                    </React.Fragment>
                  );
                })}
            </>
          ) : (
            <>
              <Typography sx={{ fontSize: 13 }}>
                <strong>Start : </strong>
                {moment(program?.start_time, "HH").format("LT")}
              </Typography>
              <Typography sx={{ fontSize: 13 }}>
                <strong>End : </strong>
                {moment(program?.end_time, "HH").format("LT")}
              </Typography>
            </>
          )}
          <Typography variant="caption">{program?.description}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            autoFocus
            onClick={handleConfirm}
          >
            Yes, Proceed
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            variant="outlined"
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default BookingConfirmationDialog;
