import { Dialog, DialogContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TickMark, crossSvg } from '../../assets/Images/Images';

const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: 479,
    },
  },
  closeButton: {
    border: 'none',
    width: '20px',
    height: '20px',
    background: `url(${crossSvg}) no-repeat`,
    '&:focus-visible': {
      //background: '#D6D6D6',
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
    '&:hover': {
      opacity: 0.75,
    },
  },
  crossMark: {
    height: 17,
    width: 17,
    backgroundColor: '#ffffff',
    '&:hover': {
      opacity: 0.75,
      backgroundColor: '#fff',
    },
  },
  signupSuccessDialog: {
    padding: '25px 24px 85px !important',
  },
};

const Popup = ({ classes, handleClose, open, existingUser }) => {
  const navigate = useNavigate();
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => handleClose()}
      aria-describedby='signup_success'
      sx={styles.dialog}
      PaperProps={{
        style: { borderRadius: 25 },
      }}
    >
      <div style={{ textAlign: 'right', padding: '10% 10% 0 0' }}>
        <button aria-label='close' onClick={() => handleClose()} style={styles.closeButton}></button>
      </div>
      <DialogContent sx={styles.signupSuccessDialog}>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-4%' }}>
            <img src={TickMark} alt='Signup Success' height={130} width={130} />
          </div>
          {existingUser ? (
            <div style={{ paddingTop: 19 }}>
              <div style={{ fontWeight: 'bold', fontSize: 25, textAlign: 'center' }}>Thanks for updating your information.</div>
              <div style={{ fontSize: 20, textAlign: 'center' }}>
                {`We've already saved a seat for you. Check your Inbox for details soon and head to our `}
                <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate('/gallery')}>
                  Gallery
                </span>
                {` page for glimpses of past editions.`}
              </div>
            </div>
          ) : (
            <div style={{ paddingTop: 19 }}>
              <div style={{ fontWeight: 'bold', fontSize: 25, textAlign: 'center' }}>
                Thank you for signing up <br />
                to attend Target Elevate 2024.
              </div>
              <div style={{ fontSize: 20, textAlign: 'center' }}>
                {`We'll get back to you soon! Until then, check out our `}
                <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate('/gallery')}>
                  Gallery
                </span>
                {` page for glimpses of past editions.`}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
