import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiConfig from "../apiConfig";

const { api } = apiConfig;

export const Loginapi = createApi({
  reducerPath: "LoginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: api.host,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().session.authToken;
      if (token) {
        headers.set("authorization", token);
      }
      return headers;
    },
  }),
  tagTypes: ["Login"],
  endpoints: (builder) => ({
    loginValidate: builder.mutation({
      query: ({ eventId, loginData }) => ({
        url: `content/events/${eventId}/participant/auth/login`,
        method: "POST",
        params: {
          key: api.key,
        },
        body: {
          ...loginData,
        },
      }),
      providesTags: ["Login"],
    }),
  }),
});

export const { useLoginValidateMutation } = Loginapi;
