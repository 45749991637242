import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import RegistrationTabs from './RegistrationTabs';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { hideInVenue, showInVenue } from '../../../api/invenue';
import { useCookies } from 'react-cookie';
import { jwtDecode } from 'jwt-decode';

const AdminPanel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['token']);
  useEffect(() => {
    dispatch(showInVenue());
    return () => {
      dispatch(hideInVenue());
    };
  }, [dispatch]);

  useEffect(() => {
    const validateToken = () => {
      if (cookies?.token) {
        const data = jwtDecode(cookies?.token);
        if (data?.exp > new Date().getTime() / 1000) {
          return true;
        }
      }
      return false;
    };
    if (!validateToken() && !(window.location.hash.split('=').length > 1 && window.location.hash.split('=')[1].split('&')[0])) {
      window.location.replace('/spot');
    }
  }, [cookies?.token, dispatch, navigate, setCookie]);

  return (
    <div className='App'>
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant='contained'
            sx={{ fontWeight: 'bold', minWidth: 130, textTransform: 'none', backgroundColor: '#cc0000' }}
            startIcon={<Add />}
            onClick={() => {
              navigate('/registeronspot?isAdmin=true&ismobileapp=true');
            }}
          >
            Register
          </Button>
        </Box>

        <Box>
          <RegistrationTabs />
        </Box>
      </Box>
    </div>
  );
};

export default AdminPanel;
