import React, { Fragment, useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Button,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ViewSpeaker from "../../shared/ViewSpeaker";
import moment from "moment";
import { useSelector } from "react-redux";
import _ from "lodash";

const MentorCard = (props) => {
  const eventId = useSelector((state) => state.tenant.eventId);
  const userId = useSelector((state) => state.session.userId);
  const company = useSelector((state) => state.session.company);
  const email = useSelector((state) => state.session.email);
  const {
    bookMentorship,
    cancelMentorship,
    cancelInterest,
    showInterest,
    setSlotSelection,
    slotSelection,
    mentorshipResponseData,
  } = props;
  const [open, setOpen] = useState(false);
  const [speakerForView, setSpeakerForView] = useState({});
  const [mentorshipListData, setMentorshipListData] = useState([]);

  useEffect(() => {
    if (mentorshipResponseData) {
      const mentorshipData = [...mentorshipResponseData];
      mentorshipData.map((mentorship, index) => {
        const item = { ...mentorship };
        let result = _.filter(mentorship.speakers, {
          sessions: [{ status: "CONFIRMED", member: userId }],
        });
        item["bookedSpeakers"] = result;
        mentorshipData[index] = item;
        return mentorship;
      });
      setMentorshipListData(mentorshipData);
    }
  }, [mentorshipResponseData, userId]);

  const handleClickOpen = (speakerData) => {
    setOpen(true);
    setSpeakerForView(speakerData);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleSlotSelection = (e) => {
    setSlotSelection(e.target.value);
  };

  const handleSlotBooking = (speakerId, mentorshipId) => {
    bookMentorship({
      eventId,
      mentorshipId,
      speakerid: speakerId,
      slotid: slotSelection,
    });
  };

  const handleCancelBooking = (speakerId, mentorshipId, slotId) => {
    cancelMentorship({
      eventId,
      mentorshipId,
      speakerid: speakerId,
      slotid: slotId,
    });
  };

  const handleCancelInterest = (speakerId, mentorshipId) => {
    cancelInterest({
      eventId,
      mentorshipId,
      speakerid: speakerId,
    });
  };

  const handleShowInterest = (speakerId, mentorshipId) => {
    showInterest({
      eventId,
      mentorshipId,
      speakerid: speakerId,
    });
  };

  const getSessionTimings = (timeData) => {
    if (timeData) {
      let slotValues = timeData.split("-");
      let startValue = moment(slotValues[0], ["h:mm"]).toDate();
      let startTime = moment(startValue).format("hh:mm A");
      let endValue = moment(slotValues[1], ["h:mm"]).toDate();
      let endTime = moment(endValue).format("hh:mm A");

      return startTime + "-" + endTime;
    }
  };

  const formatDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  const todayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = mm + "/" + dd + "/" + yyyy;
    return formattedToday;
  };

  const checkRestrictions = (mentorshipItem) => {
    const { restrictions } = mentorshipItem;
    const {
      booking_enabled,
      booking_start_date,
      booking_end_date,
      exclude_target_members,
    } = restrictions ? restrictions : "";
    if (!booking_enabled) {
      return false;
    }
    if (booking_enabled) {
      if (
        !(
          formatDate(booking_start_date) <= todayDate() &&
          todayDate() <= formatDate(booking_end_date)
        )
      ) {
        return false;
      }
    }
    if (exclude_target_members) {
      if (
        company.toLowerCase().includes("target") &&
        email.includes("target.com")
      ) {
        return false;
      }
    }
    return true;
  };

  const checkBookingsPerPerson = (mentorshipItem, index) => {
    const { restrictions } = mentorshipItem;
    const { bookings_per_person } = restrictions;
    if (bookings_per_person > mentorshipItem?.bookedSpeakers?.length) {
      return false;
    }
    return true;
  };

  const checkTimingsToBook = (mentorshipItem, speaker, sessionSlot) => {
    var mentorshipVal = [];
    mentorshipItem.bookedSpeakers.map((item) => {
      return mentorshipVal.push(
        item.sessions.filter(
          ({ status, member }) => member === userId && status === "CONFIRMED"
        )[0]
      );
    });
    if (!checkBookingsPerPerson(mentorshipItem)) {
      let disableTheBooking = false;
      mentorshipVal.map((bookedItem) => {
        if (bookedItem.parent !== speaker._id) {
          disableTheBooking = disableTheBooking
            ? disableTheBooking
            : checkslots(bookedItem, sessionSlot);
        }
        return 0;
      });
      return disableTheBooking;
    } else {
      return false;
    }
  };

  const checkslots = (bookedItem, sessionSlot) => {
    return checkTimings(bookedItem, sessionSlot);
  };

  const checkTimings = (bookedItemSlot, sessionSlot) => {
    var sessionTimes = sessionSlot.split("-");
    var bookedItemSlotTimes = bookedItemSlot.slot.split("-");
    const sessionSlotStartTime = getSessionTime(sessionTimes[0]);
    const sessionSlotEndTime = getSessionTime(sessionTimes[1]);
    const bookedItemSlotStartTime = getSessionTime(bookedItemSlotTimes[0]);
    const bookedItemSlotEndTime = getSessionTime(bookedItemSlotTimes[1]);

    var temp1 = false;
    var temp2 = false;
    if (bookedItemSlot.slot === sessionSlot) {
      return true;
    }

    if (
      moment(sessionSlotStartTime, "hh:mm:ss").isBetween(
        moment(bookedItemSlotStartTime, "hh:mm:ss"),
        moment(bookedItemSlotEndTime, "hh:mm:ss")
      ) ||
      moment(bookedItemSlotStartTime, "hh:mm:ss").isBetween(
        moment(sessionSlotStartTime, "hh:mm:ss"),
        moment(sessionSlotEndTime, "hh:mm:ss")
      )
    ) {
      temp1 = true;
    }

    if (
      moment(sessionSlotEndTime, "hh:mm:ss").isBetween(
        moment(bookedItemSlotStartTime, "hh:mm:ss"),
        moment(bookedItemSlotEndTime, "hh:mm:ss")
      ) ||
      moment(bookedItemSlotEndTime, "hh:mm:ss").isBetween(
        moment(sessionSlotStartTime, "hh:mm:ss"),
        moment(sessionSlotEndTime, "hh:mm:ss")
      )
    ) {
      temp2 = true;
    }

    if (temp1 || temp2) {
      return true;
    }

    return false;
  };

  const getSessionTime = (val) => {
    let timeVal = moment(val, ["h:mm"]).format("hh:mm:ss");

    return timeVal;
  };
  return (
    <>
      <Grid container sx={{ width: "90%", margin: "0 auto", gap: 5 }}>
        {mentorshipListData &&
          mentorshipListData.length > 0 &&
          mentorshipListData.map((item, index) => {
            return (
              item.show_on_website && (
                <Fragment key={index}>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: 600, py: 3, flex: "0 0 100%" }}
                  >
                    {item.name}
                  </Typography>
                  {item.speakers &&
                    item.speakers.length > 0 &&
                    item.speakers.map((speaker, speakerIndex) => {
                      const bookedSession = speaker.sessions.filter(
                        ({ status, member }) =>
                          member === userId && status === "CONFIRMED"
                      )[0];
                      const availableSession = speaker.sessions.filter(
                        ({ status }) => status === "AVAILABLE"
                      )[0];
                      const waitingListSession = speaker.sessions.filter(
                        ({ status, member }) =>
                          member === userId && status === "WAITING_LIST"
                      )[0];
                      // setSlotSelection(bookedSession?._id);
                      return (
                        <Grid item xs={5} key={speakerIndex}>
                          <Card
                            sx={{
                              border: "1px solid #9B6A42",
                              padding: "20px ",
                              borderRadius: "20px !important",
                            }}
                          >
                            <CardHeader
                              avatar={
                                <Avatar
                                  sx={{ width: 100, height: 100 }}
                                  alt={speaker.user.name}
                                  src={speaker.engagement_image}
                                />
                              }
                              title={
                                <>
                                  <Typography
                                    variant="h3"
                                    sx={{ fontSize: 22, fontWeight: 600 }}
                                  >
                                    {speaker.user.name}
                                  </Typography>
                                </>
                              }
                              subheader={speaker.meta.designation}
                            ></CardHeader>
                            <CardContent>
                              <Typography varinat="body1">
                                {item.name}
                              </Typography>
                              <Button
                                onClick={() => handleClickOpen(speaker)}
                                aria-label={`View more about`}
                                startIcon={
                                  <AddIcon
                                    sx={{
                                      color: "#be854c",
                                      fontSize: "2rem !important",
                                    }}
                                  />
                                }
                              >
                                <span
                                  style={{
                                    textTransform: "none ",
                                    color: "#000000 ",
                                    fontSize: "15px ",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Read more
                                </span>
                              </Button>
                              {checkRestrictions(item) && (
                                <>
                                  <Typography
                                    variant="h6"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    Reserve your seat
                                  </Typography>
                                  <FormControl key={index}>
                                    <RadioGroup
                                      row
                                      aria-labelledby="mentors-slot"
                                      name="mentors-slot-timings"
                                      value={slotSelection}
                                      onChange={(e) => handleSlotSelection(e)}
                                    >
                                      {speaker.sessions.map(
                                        (session, index) => {
                                          return (
                                            <>
                                              {session.slot !== null && (
                                                <FormControlLabel
                                                  value={session._id}
                                                  key={index}
                                                  disabled={
                                                    checkTimingsToBook(
                                                      item,
                                                      speaker,
                                                      session.slot
                                                    ) ||
                                                    bookedSession?._id ||
                                                    session.status ===
                                                      "CONFIRMED"
                                                  }
                                                  checked={
                                                    session._id ===
                                                    bookedSession?._id
                                                      ? true
                                                      : session._id ===
                                                        slotSelection
                                                      ? true
                                                      : false
                                                  }
                                                  control={
                                                    <Radio
                                                      sx={{
                                                        "&.Mui-checked": {
                                                          color:
                                                            "#be854c !important",
                                                        },
                                                        "&.Mui-disabled": {
                                                          color:
                                                            "#aeaeae !important",
                                                        },
                                                      }}
                                                    />
                                                  }
                                                  label={getSessionTimings(
                                                    session.slot
                                                  )}
                                                />
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    </RadioGroup>
                                  </FormControl>

                                  <Box>
                                    {bookedSession && bookedSession._id ? (
                                      <Button
                                        type="button"
                                        variant="contained"
                                        onClick={() =>
                                          handleCancelBooking(
                                            speaker.user._id,
                                            item._id,
                                            bookedSession._id
                                          )
                                        }
                                        sx={{
                                          backgroundColor: "#be854c",
                                          color: "#000",
                                          height: 40,
                                          mt: 3,
                                          "&:focus-visible": {
                                            outline: "2px dotted #666666",
                                          },
                                          "&:hover": {
                                            backgroundColor: "#be854c",
                                          },
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    ) : (
                                      availableSession?._id && (
                                        <Button
                                          type="button"
                                          variant="contained"
                                          onClick={() =>
                                            handleSlotBooking(
                                              speaker.user._id,
                                              item._id
                                            )
                                          }
                                          disabled={checkBookingsPerPerson(
                                            item,
                                            speakerIndex
                                          )}
                                          sx={{
                                            backgroundColor: "#be854c",
                                            color: "#000",
                                            height: 40,
                                            mt: 3,
                                            "&:focus-visible": {
                                              outline: "2px dotted #666666",
                                            },
                                            "&:hover": {
                                              backgroundColor: "#be854c",
                                            },
                                          }}
                                        >
                                          Book Now
                                        </Button>
                                      )
                                    )}

                                    {waitingListSession?._id ? (
                                      <Button
                                        type="button"
                                        variant="contained"
                                        onClick={() =>
                                          handleCancelInterest(
                                            speaker.user._id,
                                            item._id
                                          )
                                        }
                                        sx={{
                                          backgroundColor: "#be854c",
                                          color: "#000",
                                          height: 40,
                                          mt: 3,
                                          "&:focus-visible": {
                                            outline: "2px dotted #666666",
                                          },
                                          "&:hover": {
                                            backgroundColor: "#be854c",
                                          },
                                        }}
                                      >
                                        Cancel Interest
                                      </Button>
                                    ) : (
                                      availableSession === undefined &&
                                      bookedSession === undefined && (
                                        <Button
                                          type="button"
                                          variant="contained"
                                          onClick={() =>
                                            handleShowInterest(
                                              speaker.user._id,
                                              item._id
                                            )
                                          }
                                          // disabled={checkBookingsPerPerson(
                                          //   item,
                                          //   speakerIndex
                                          // )}
                                          sx={{
                                            backgroundColor: "#be854c",
                                            color: "#000",
                                            height: 40,
                                            mt: 3,
                                            "&:focus-visible": {
                                              outline: "2px dotted #666666",
                                            },
                                            "&:hover": {
                                              backgroundColor: "#be854c",
                                            },
                                          }}
                                        >
                                          Show Interest
                                        </Button>
                                      )
                                    )}
                                  </Box>
                                </>
                              )}
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    })}
                </Fragment>
              )
            );
          })}
      </Grid>
      <ViewSpeaker
        open={open}
        onClose={onClose}
        name={speakerForView?.user?.name}
        company={speakerForView?.user?.company}
        designation={speakerForView?.meta?.designation}
        bio={speakerForView?.meta?.bio}
        image={speakerForView?.engagement_image}
      ></ViewSpeaker>
    </>
  );
};

export default MentorCard;
