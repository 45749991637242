import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Grid,
  Typography,
  Chip,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";

const getSessionTime = (val) => {
  let timeVal = moment(val, ["h:mm"]).format("hh:mm:ss");

  return timeVal;
};

const getSessionTimings = (timeData) => {
  if (timeData) {
    let slotValues = timeData.split("-");
    let startValue = moment(slotValues[0], ["h:mm"]).toDate();
    let startTime = moment(startValue).format("hh:mm A");
    let endValue = moment(slotValues[1], ["h:mm"]).toDate();
    let endTime = moment(endValue).format("hh:mm A");

    return startTime + "-" + endTime;
  }
};

const checkTimings = (bookedItemSlot, sessionSlot) => {
  var sessionTimes = sessionSlot.split("-");
  var bookedItemSlotTimes = bookedItemSlot.split("-");
  const sessionSlotStartTime = getSessionTime(sessionTimes[0]);
  const sessionSlotEndTime = getSessionTime(sessionTimes[1]);
  const bookedItemSlotStartTime = getSessionTime(bookedItemSlotTimes[0]);
  const bookedItemSlotEndTime = getSessionTime(bookedItemSlotTimes[1]);

  var temp1 = false;
  var temp2 = false;
  if (bookedItemSlot === sessionSlot) {
    return true;
  }

  if (
    moment(sessionSlotStartTime, "hh:mm:ss").isBetween(
      moment(bookedItemSlotStartTime, "hh:mm:ss"),
      moment(bookedItemSlotEndTime, "hh:mm:ss")
    ) ||
    moment(bookedItemSlotStartTime, "hh:mm:ss").isBetween(
      moment(sessionSlotStartTime, "hh:mm:ss"),
      moment(sessionSlotEndTime, "hh:mm:ss")
    )
  ) {
    temp1 = true;
  }

  if (
    moment(sessionSlotEndTime, "hh:mm:ss").isBetween(
      moment(bookedItemSlotStartTime, "hh:mm:ss"),
      moment(bookedItemSlotEndTime, "hh:mm:ss")
    ) ||
    moment(bookedItemSlotEndTime, "hh:mm:ss").isBetween(
      moment(sessionSlotStartTime, "hh:mm:ss"),
      moment(sessionSlotEndTime, "hh:mm:ss")
    )
  ) {
    temp2 = true;
  }

  if (temp1 || temp2) {
    return true;
  }

  return false;
};

const MentorshipBooking = (props) => {
  const {
    mentorships = [],
    setSelectedSpeakers,
    selectedSpeakers,
    selectedSlots,
    setSelectedSlots,
    bookedSlotTimings,
    setBookedSlotTimings,
    displayData,
    setDisplayData,
    interests = [],
  } = props;
  const favMentors = interests.map((x) => {
    return x?.speaker?._id;
  });
  const isBookingReachedMax = (mentorshipItem, noOfSlots) => {
    const { restrictions } = mentorshipItem;
    const { bookings_per_person } = restrictions;
    if (bookings_per_person <= noOfSlots?.length) {
      return true;
    } else {
      return false;
    }
  };

  const checkSlotTimingConflict = (targetSlot) => {
    let disableBooking = false;
    bookedSlotTimings.map((bs) => {
      disableBooking = disableBooking
        ? disableBooking
        : checkTimings(bs, targetSlot);
      return 0;
    });
    return disableBooking;
  };

  return (
    <Box>
      {mentorships.length > 0 &&
        mentorships.map((ms) => {
          const maxBookingReached = isBookingReachedMax(ms, selectedSlots);
          return (
            <React.Fragment key={ms?._id}>
              <Box>
                {ms?.speakers &&
                  ms.speakers.map((speaker) => {
                    const name = speaker?.user?.name;
                    const company = speaker?.user?.company;
                    const sessions = speaker?.sessions;
                    return (
                      <>
                        <Box
                          sx={{
                            p: 1,
                            pb: 2,
                            pt: 2,
                            display: "flex",
                            alignItems: "center",
                            position: "relative",
                          }}
                        >
                          <Avatar
                            src={speaker?.engagement_image}
                            sx={{ width: 80, height: 80 }}
                          ></Avatar>
                          {favMentors && favMentors.includes(speaker?._id) && (
                            <FavoriteIcon
                              sx={{
                                position: "absolute",
                                color: "red",
                                top: 20,
                                left: 70,
                              }}
                            />
                          )}
                          <Box sx={{ p: 1, pl: 3, width: "25%" }}>
                            <Typography
                              sx={{ fontWeight: "bold", color: "#4d4d4d" }}
                            >
                              {name}
                            </Typography>
                            <Typography sx={{ color: "#4d4d4d" }}>
                              {company}
                            </Typography>
                          </Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <Grid
                              container
                              sx={{ width: "100%" }}
                              justifyContent="space-around"
                            >
                              {sessions &&
                                sessions.map((slot) => {
                                  const isAvailable =
                                    slot?.status === "AVAILABLE";
                                  const isSelected = selectedSlots.includes(
                                    `${speaker?.user?._id}:${slot?._id}`
                                  );
                                  const speakerSelected =
                                    selectedSpeakers.includes(
                                      speaker?.user?._id
                                    );
                                  const timingNotAllowed =
                                    checkSlotTimingConflict(slot?.slot);
                                  const disableTheCheck =
                                    !isAvailable ||
                                    ((speakerSelected ||
                                      maxBookingReached ||
                                      timingNotAllowed) &&
                                      !isSelected);
                                  return (
                                    <Grid item>
                                      <Card elevation={0} sx={{ p: 1 }}>
                                        <CardActionArea
                                          sx={{ p: 1 }}
                                          disabled={disableTheCheck}
                                          onClick={() => {
                                            setSelectedSpeakers(
                                              isSelected
                                                ? selectedSpeakers.filter(
                                                    (s) =>
                                                      s !== speaker?.user?._id
                                                  )
                                                : _.uniq([
                                                    ...selectedSpeakers,
                                                    `${speaker?.user?._id}`,
                                                  ])
                                            );
                                            setSelectedSlots(
                                              isSelected
                                                ? selectedSlots.filter(
                                                    (val) =>
                                                      val !==
                                                      `${speaker?.user?._id}:${slot?._id}`
                                                  )
                                                : _.uniq([
                                                    ...selectedSlots,
                                                    `${speaker?.user?._id}:${slot?._id}`,
                                                  ])
                                            );
                                            setBookedSlotTimings(
                                              isSelected
                                                ? [
                                                    ...bookedSlotTimings.filter(
                                                      (timing) =>
                                                        timing !== slot?.slot
                                                    ),
                                                  ]
                                                : [
                                                    ...bookedSlotTimings,
                                                    slot?.slot,
                                                  ]
                                            );
                                            setDisplayData(
                                              isSelected
                                                ? _({
                                                    ...displayData,
                                                    [speaker?.user?._id]: null,
                                                  })
                                                    .omitBy(_.isNull)
                                                    .value()
                                                : {
                                                    ...displayData,
                                                    [speaker?.user?._id]: {
                                                      name: speaker?.user?.name,
                                                      slot_data:
                                                        getSessionTimings(
                                                          slot?.slot
                                                        ),
                                                    },
                                                  }
                                            );
                                          }}
                                        >
                                          <Chip
                                            sx={{
                                              height: 40,
                                              width: 200,
                                              borderRadius: 30,
                                              background: isSelected
                                                ? "linear-gradient(90deg, rgba(215,12,15,1) 0%, rgba(173,15,15,1) 100%)"
                                                : disableTheCheck
                                                ? "linear-gradient(90deg, rgba(246,246,246,1) 0%, rgba(226,226,226,1) 100%);"
                                                : "linear-gradient(90deg, rgba(76,175,80,1) 0%, rgba(27,94,32,1) 100%);",
                                              color: isSelected
                                                ? "#fff"
                                                : disableTheCheck
                                                ? "#4d4d4d"
                                                : "#fff",
                                              fontWeight: isSelected
                                                ? "bold"
                                                : disableTheCheck
                                                ? "normal"
                                                : "bold",
                                            }}
                                            label={getSessionTimings(
                                              slot?.slot
                                            )}
                                          />
                                        </CardActionArea>
                                      </Card>
                                    </Grid>
                                  );
                                })}
                            </Grid>
                          </Box>
                        </Box>
                      </>
                    );
                  })}
              </Box>
            </React.Fragment>
          );
        })}
    </Box>
  );
};

export default MentorshipBooking;
