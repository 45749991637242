import { useState } from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import videos from '../../../SiteData/Gallery/Videos2021.json';
import Video from '../../../Components/Video/VideoPlayer';

const styles = {
  topicName: {
    fontWeight: 'bold',
    lineHeight: '1.5em',
    // fontFamily: 'HelveticaforTargetBold',
    margin: 0,
    padding: 0,
    color: '#6d6d6d',
  },
  topicTitle: {
    padding: '20px 0 6px 0',
    margin: 0,
    fontSize: 22,
    // fontFamily: 'HelveticaforTargetBold',
    lineHeight: 1,
  },
  topicBio: {
    padding: 0,
    margin: 0,
    lineHeight: '1.5em',
    // fontFamily: 'HelveticaforTargetRegular',
    fontSize: 16,
  },
  image: {
    width: '100%',
    cursor: 'pointer',
  },
  sectionTitle: {
    // fontFamily: 'HelveticaforTarget-Light',
    color: '#333333',
    fontWeight: 350,
    margin: 0,
  },
  underLine: {
    border: '2px solid #be854c',
    backgroundColor: '#be854c',
    width: 120,
    marginLeft: 5,
  },
  introSection: {
    // fontFamily: 'HelveticaforTargetRegular',
    fontSize: 16,
    color: '#333333',
    paddingTop: 30,
    lineHeight: '1.5em',
    paddingBottom: '1.5rem',
  },
};

const Videos2021 = ({ classes }) => {
  const [open, setOpen] = useState(false);
  const [url, setURL] = useState('');
  const mobile = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const tab = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const [captions, setCaptions] = useState('');
  const [poster, setPoster] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const handleClick = (videoObj) => {
    setURL(videoObj.videoUrl);
    setCaptions(videoObj.captions);
    setPoster(videoObj.url);
    setVideoTitle(videoObj.title);
    setOpen(true);
  };

  return (
    <Grid>
      {videos.videos2021.map((ele) => (
        <Grid key={ele.topic}>
          <Grid>
            <h2 className={classes.sectionTitle} style={{ fontSize: !mobile ? 34 : !tab ? 40 : 55 }}>
              {ele.topic}
            </h2>
            <div className={classes.underLine} />
            <div className={classes.introSection} style={{ width: desktop ? '45%' : '100%' }}>
              {ele.intro}
            </div>
          </Grid>
          <Grid container>
            {ele.videos.map((video) => (
              <Grid item lg={4} md={6} sm={12} padding={!mobile ? '1rem 0' : '1.5rem'} key={video.url}>
                <button onClick={() => handleClick(video)} style={{ border: 'none', padding: 0, background: '#ffffff' }}>
                  <img
                    src={video.url}
                    aria-label="{'play video of ' + video.name}"
                    alt={'play video of ' + video.name}
                    title={'play video of ' + video.name}
                    className={classes.image}
                  />
                </button>
                <h2 className={classes.topicTitle}>{video.title} </h2>
                <p className={classes.topicName}>{video.name}</p>
                <p className={classes.topicBio}>{video.bio}</p>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
      <Video open={open} handleClose={() => setOpen(false)} videoSrc={url} captions={captions} poster={poster} videoTitle={videoTitle} />
    </Grid>
  );
};

export default withStyles(styles)(Videos2021);
