import React from "react";
import { Box } from "@mui/material";
import { TickMark } from "../../assets/Images/Images";

const SuccessPopup = (props) => {
  const { dataText } = props;
  return (
    <Box sx={{ width: "100%", height: 320 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 8,
        }}
      >
        <img
          src={`${TickMark}?a=${Math.random()}`}
          alt={dataText}
          height={130}
          width={130}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          fontSize: 20,
          pt: 3,
        }}
      >
        {dataText}
      </Box>
    </Box>
  );
};

export default SuccessPopup;
