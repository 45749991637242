import React from "react";
import { Box, CardMedia, Grid, Typography } from "@mui/material";
import timeicon from "./time_icon.png";
// import agenda_rows from "./mock.json";

const AgendaTime = (props) => {
  const { time = "", title = "", subtitle = "" } = props;
  return (
    <Box sx={{ borderBottom: "1px solid #dadada", pb: 1, pt: 1 }}>
      <Box sx={{ display: "flex" }}>
        <Grid container>
          {time && (
            <Grid item xs={12} sm={4} sx={{ display: "flex" }}>
              <CardMedia
                sx={{
                  height: { sm: 27, xs: 23 },
                  width: { sm: 27, xs: 23 },
                  backgroundSize: "contain",
                  mr: 1,
                }}
                image={timeicon}
              />
              <Typography
                sx={{
                  fontSize: { sm: 19, xs: 17 },
                  fontWeight: "bold",
                  color: "#cc0000",
                }}
              >
                {time}
              </Typography>
            </Grid>
          )}
          {title && (
            <Grid item xs={12} sm={4}>
              <Typography
                sx={{
                  fontSize: { sm: 19, xs: 17 },
                  fontWeight: "bold",
                  color: "#cc0000",
                  pl: 1,
                  pt: { xs: 1, sm: 0 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                {title}
              </Typography>
            </Grid>
          )}
          {subtitle && (
            <Grid item xs={12} sm={4}>
              <Typography
                sx={{
                  fontSize: 13,
                  fontWeight: "bold",
                  color: "#7b7b7b",
                  pl: 1,
                }}
              >
                {subtitle}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

const SpeakerInfo = (props) => {
  const { name = "", designation = "", company = "" } = props;
  return (
    <Box sx={{ pb: 1 }}>
      <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>{name}</Typography>
      <Typography sx={{ fontWeight: "500", fontSize: 17, color: "#646464" }}>
        {designation}
      </Typography>
      {company && (
        <Typography sx={{ fontWeight: "400", fontSize: 17, color: "#949494" }}>
          {company}
        </Typography>
      )}
    </Box>
  );
};

const AgendaRow = (props) => {
  const { programs = [] } = props;
  return (
    <Box sx={{ borderBottom: "1px solid #dadada" }}>
      {programs.map((program, pid) => {
        return (
          <Grid
            container
            key={pid}
            sx={{
              alignItems: "center",
              background: (pid + 1) % 2 === 1 ? "#f7f7f7" : "#fff",
              p: 2,
            }}
          >
            <Grid item xs={12} md={4}>
              <Box>
                {program?.speakers?.length > 0 &&
                  program.speakers.map((speaker, sid) => {
                    return <SpeakerInfo key={sid} {...speaker} />;
                  })}
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                sx={{
                  color: { xs: "#cc0000", sm: "#646464" },
                  fontSize: 18,
                  fontWeight: 500,
                }}
              >
                {program?.prefix && (
                  <>
                    <strong>{program?.prefix}</strong>
                    <br />
                  </>
                )}
                {program?.name}
              </Typography>
            </Grid>

            <Grid
              item
              sx={{ display: { xs: "none", sm: "block" } }}
              xs={12}
              md={4}
            >
              {program?.description}
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

const AgendaCustom = (props) => {
  const { agenda_rows = [] } = props;

  return (
    <Box sx={{ ml: "4.7%", mr: "4.7%", pt: 2, pb: 1 }}>
      {agenda_rows &&
        agenda_rows.length > 0 &&
        agenda_rows.map((row, rowid) => {
          return (
            <Box
              key={rowid}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {(row?.time || row?.title) && (
                <AgendaTime
                  time={row?.time}
                  title={!row?.speakers && row?.title ? row?.title : ""}
                  subtitle={row?.subtitle ? row.subtitle : ""}
                />
              )}
              <Box sx={{ p: 0 }}>
                {row?.programs && <AgendaRow programs={row?.programs} />}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default AgendaCustom;
