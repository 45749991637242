import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
//import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { crossSvg as closeSvg } from '../../assets/Images/Images';
import { IconButton } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import copy from 'copy-to-clipboard';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    // height: '450px',
    // width: '750px',
  },
  videoContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    border: '1px solid rgba(0,0,0,.2)',
    boxShadow: '24px ',
    borderRadius: '.3rem',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    [theme.breakpoints.up('md')]: {
      //   height: '50%',
      width: '50%',
    },
  },
  videoPlayer: {
    padding: '0 5%',
    width: '90%',
    height: '100%',
    // [theme.breakpoints.down('md')]: {
    //     padding: '0 7% 7% 7%',
    // },
  },
  closeIcon: {
    background: `url(${closeSvg}) no-repeat`,
    color: '#333333 !important',
    cursor: 'pointer',
    width: '20px',
    height: '20px',
    border: 'none',
    [theme.breakpoints.down('md')]: {
      width: '13px',
    },
  },
  visuallyHidden: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
  closeIconContainer: {
    textAlign: 'right',
    padding: '2% 2% 0 0',
    [theme.breakpoints.down('md')]: {
      padding: '3% 3% 0 0',
    },
  },
  copyLink: {
    textAlign: 'center',
    '& button': {
      // paddingTop: '7px',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
      '&:focus': {
        outline: '1px solid #d3d3d3',
      },
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      paddingBottom: '6px',
    },
  },
  shareIcons: {
    fontSize: '20px !important',
    // '& :hover': {
    //   color: '#cc0000',
    // }
  },
  copyMsg: {
    // position: 'absolute',
    // top: '31px',
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#cc0000',
    left: '79px',
  },
}));

const VideoPlayer = ({ open, handleClose, videoSrc, poster, videoTitle, captions }) => {
  const classes = useStyles();
  const [visible, setVisible] = React.useState(false);
  const [copySuccess, setCopySuccess] = React.useState('');
  const pattern = /elevatewebsiteproduction\/(.*)/;
  function buildShareUrl(videoObj) {
    const { track, videoSrc, poster, title } = videoObj;
    const shareUrl = `${window.location.protocol}//${window.location.host}/videostory?vidsrc%3D${videoSrc
      ?.match(pattern)[1]
      ?.replace(/ /g, '%20')}%26track%3D${track?.match(pattern)[1]?.replace(/ /g, '%20')}%26poster%3D${poster.match(pattern)[1]}%26title%3D${title?.replace(
      / /g,
      '%20'
    )}`;
    return shareUrl;
  }
  function copyToClipboard(videoObj) {
    copy(buildShareUrl(videoObj));
    setCopySuccess('copied');
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 2000);
  }

  const shareThePost = (action) => {
    const { media, videoObj } = action;
    const { title } = videoObj;
    switch (media) {
      case 'twitter':
        const twitter = `https://twitter.com/intent/tweet?text=${title.replace(/ /, '%20')}&url=${buildShareUrl(videoObj)}&related=`;
        window.open(twitter);
        break;
      case 'linkedIn':
        const linkedIn = `https://www.linkedin.com/sharing/share-offsite/?url=${buildShareUrl(videoObj)}&title%3D${title.replace(
          / /,
          '%20'
        )}&ro%3Dfalse&summary%3D&source%3D`;
        window.open(linkedIn);
        break;
      case 'copyURL':
        // copy(window.location.href)
        break;
      default:
        break;
    }
  };
  return (
    <div>
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript>
        {`
                    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WLZJSJZ"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
      <Modal open={open} role='dialog' onClose={handleClose} aria-label='video player dialog' className={classes.modalContainer}>
        <Box className={classes.videoContainer}>
          <h2 className={classes.visuallyHidden}>video player</h2>
          {/* <CloseSharpIcon className={classes.closeIcon} onClick={handleClose} /> */}
          <div className={classes.closeIconContainer}>
            <button className={classes.closeIcon} onClick={handleClose} aria-label='close'>
              {/* <img src={closeSvg} alt='clsoe' onClick={handleClose} /> */}
            </button>
          </div>
          <video
            playsInline
            autoPlay={true}
            controls
            className={classes.videoPlayer}
            controlsList='nodownload'
            crossOrigin='anonymous'
            poster={poster ? poster : 'https://gfc.target.com/elevatewebsiteproduction/elevatewebsite2022_video_poster.png'}
          >
            <source src={videoSrc} type='video/mp4' />
            <track src={captions} kind='subtitles' srcLang='en' label='English' />
          </video>
          <div className={classes.copyLink}>
            <IconButton
              aria-label='Share this post on Twitter'
              className={classes.shareButtons}
              onClick={() =>
                shareThePost({
                  media: 'twitter',
                  videoObj: {
                    track: captions,
                    videoSrc: videoSrc,
                    poster: poster ? poster : 'https://gfc.target.com/elevatewebsiteproduction/elevatewebsite2022_video_poster.png',
                    title: videoTitle,
                  },
                })
              }
            >
              <XIcon className={classes.shareIcons} />
            </IconButton>
            <IconButton
              onClick={() =>
                shareThePost({
                  media: 'linkedIn',
                  videoObj: {
                    track: captions,
                    videoSrc: videoSrc,
                    poster: poster ? poster : 'https://gfc.target.com/elevatewebsiteproduction/elevatewebsite2022_video_poster.png',
                    title: videoTitle,
                  },
                })
              }
              aria-label='Share this post on LinkedIn'
              className={classes.shareButtons}
            >
              <LinkedInIcon className={classes.shareIcons} />
            </IconButton>
            <IconButton
              style={{ position: 'relative' }}
              aria-label='Copy this post URL'
              onClick={() =>
                copyToClipboard({
                  track: captions,
                  videoSrc: videoSrc,
                  poster: poster ? poster : 'https://gfc.target.com/elevatewebsiteproduction/elevatewebsite2022_video_poster.png',
                  title: videoTitle,
                })
              }
              className={classes.shareButtons}
            >
              <LinkIcon className={classes.shareIcons} />
            </IconButton>
            {visible ? <div className={classes.copyMsg}>{copySuccess}</div> : ''}
          </div>
        </Box>
      </Modal>
    </div>
  );
};
export default VideoPlayer;
