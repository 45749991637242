import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiConfig from '../apiConfig';

const { api } = apiConfig;

export const RegistrationApi = createApi({
  reducerPath: 'RegistrationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api.host,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().session.authToken;
      if (token) {
        headers.set('authorization', token);
      }
      return headers;
    },
  }),
  tagTypes: ['Register'],
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: ({ eventId, registerData }) => ({
        url: `content/events/${eventId}/participant/auth/register`,
        method: 'POST',
        params: {
          key: api.key,
        },
        body: {
          ...registerData,
        },
      }),
      providesTags: ['Login'],
    }),
    updateYtrUser: builder.mutation({
      query: ({ eventId, userDataToUpdate }) => ({
        url: `content/events/${eventId}/participant/auth/update`,
        method: 'POST',
        params: {
          key: api.key,
        },
        body: {
          ...userDataToUpdate,
        },
      }),
      providesTags: ['Login'],
    }),
    getParticipantsOfEvent: builder.query({
      query: ({ filters, eventid, download = true, auth }) => ({
        url: `content/events/${eventid}/participants/list`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${auth}`,
        },
        params: {
          key: api.key,
          ...filters,
          eventid,
          download,
        },
      }),
      providesTags: ['EventParticipants'],
    }),
  }),
});

export const { useRegisterUserMutation, useUpdateYtrUserMutation, useGetParticipantsOfEventQuery } = RegistrationApi;
