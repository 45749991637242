import React, { useEffect, useState } from "react";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import moment from "moment";
import WorkshopCard from "./WorkshopCard";
import { useSelector } from "react-redux";
import Login from "../Login/Login";
import {
  useGetActiveWorkshopsQuery,
  useBookWorkshopMutation,
  useCancelWorkshopMutation,
  useShowInterestMutation,
  useCancelInterestMutation,
} from "../../api/workshop";

const Workshop = () => {
  const loggedInUser = useSelector((state) => state.session.loggedInUser);
  const eventId = useSelector((state) => state.tenant.eventId);

  const [open, setOpen] = useState(false);
  const [successLogin, setLoginSuccess] = useState(false);
  const [workshopReponseData, setWorkshopResponseData] = useState([]);

  const { isLoading: workshopLoading, data: workshopData } =
    useGetActiveWorkshopsQuery(
      { eventId },
      { skip: !loggedInUser || !eventId, refetchOnMountOrArgChange: true }
    );

  const [bookWorkshop, { isLoading: bookingInProgress }] =
    useBookWorkshopMutation({}, { skip: !loggedInUser || !eventId });

  const [cancelBooking, { isLoading: cancelInProgress }] =
    useCancelWorkshopMutation({}, { skip: !loggedInUser || !eventId });

  const [showInterest] = useShowInterestMutation(
    {},
    { skip: !loggedInUser || !eventId }
  );

  const [cancelInterest] = useCancelInterestMutation(
    {},
    { skip: !loggedInUser || !eventId }
  );

  useEffect(() => {
    if (loggedInUser) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (workshopData) {
      setWorkshopResponseData(workshopData.data);
    }
  }, [workshopData]);

  const handleBookNow = (workshopId) => {
    bookWorkshop({ eventId, workshopId });
  };

  const handleCancel = (workshopId) => {
    cancelBooking({ eventId, workshopId });
  };

  const handleCancelInterest = (workshopId) => {
    cancelInterest({ eventId, workshopId });
  };

  const handleShowInterest = (workshopId) => {
    showInterest({ eventId, workshopId });
  };

  return (
    <>
      {loggedInUser ? (
        <Box sx={{ minHeight: "100vh" }}>
          <Box
            sx={{
              mt: 12,
              backgroundImage:
                "-webkit-linear-gradient(bottom, #E6CAAE 0%, #F3E6D9 30%)",
            }}
          >
            {workshopReponseData &&
              workshopReponseData.map((workshop, index) => (
                <Grid
                  container
                  key={index}
                  sx={{ width: "80%", margin: "0px auto" }}
                >
                  <Grid
                    item
                    md={12}
                    sm={12}
                    sx={{
                      marginTop: "20px",
                      margin: "0 auto",
                      marginBottom: "30px",
                    }}
                  >
                    {workshop?.show_on_website && (
                      <Typography
                        variant="h2"
                        sx={{
                          textAlign: "center",
                          padding: "5% 0px 2% 0",
                          fontSize: "40px",
                          color: "#000",
                        }}
                      >
                        {moment(workshop.start_date).format("dddd, MMMM D")}
                        <hr
                          style={{
                            width: "108px",
                            display: "block",
                            margin: "0 auto",
                            border: "2px solid #be854c",
                            backgroundColor: "#be854c",
                            marginTop: "10px",
                            marginBottom: "1rem",
                          }}
                          aria-hidden="true"
                        />
                      </Typography>
                    )}
                    {(bookingInProgress ||
                      cancelInProgress ||
                      workshopLoading) && (
                      <LinearProgress
                        sx={{
                          backgroundColor: "#be854c",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: "#aeaeae",
                          },
                        }}
                      ></LinearProgress>
                    )}
                    {workshop?.show_on_website && (
                      <WorkshopCard
                        workshop={workshop}
                        handleBookNow={handleBookNow}
                        handleCancel={handleCancel}
                        cancelInterest={cancelInterest}
                        showInterest={showInterest}
                        handleCancelInterest={handleCancelInterest}
                        handleShowInterest={handleShowInterest}
                      />
                    )}
                  </Grid>
                </Grid>
              ))}
          </Box>
        </Box>
      ) : (
        <Login
          open={open}
          loggedInUser={loggedInUser}
          setOpen={setOpen}
          closeOption={false}
          setLoginSuccess={setLoginSuccess}
          successLogin={successLogin}
        ></Login>
      )}
    </>
  );
};

export default Workshop;
