import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authToken: "",
  loggedInUser: "",
  userId: "",
  company: "",
  email: "",
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setUserSession: (state, action) => {
      state.authToken = action.payload
        ? action.payload.token
        : initialState.authToken;
      state.loggedInUser = action.payload.name
        ? action.payload.name
        : initialState.loggedInUser;
      state.userId = action.payload.id
        ? action.payload.id
        : initialState.userId;
      state.company = action.payload.company
        ? action.payload.company
        : initialState.company;
      state.email = action.payload.email
        ? action.payload.email
        : initialState.email;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserSession } = sessionSlice.actions;

export default sessionSlice.reducer;
