import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogContent, Box, Button, LinearProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { useLoginValidateMutation } from '../../api/login';
import { setUserSession } from '../../api/session';
import { useDispatch, useSelector } from 'react-redux';
import LoginPopup from './LoginPopup';
import SuccessPopup from './SuccessPopup';

const Login = (props) => {
  const eventId = useSelector((state) => state.tenant.eventId);
  const { loggedInUser, successLogin, setLoginSuccess, logoutSucess, open, setOpen, closeOption = true } = props;
  console.log('log in on desktop', loggedInUser, successLogin, open, setOpen, closeOption);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [loginErrorData, setLoginErrorData] = useState('');
  //   const [open, setOpen] = useState(false);
  const [loginResponseData, setLoginResponse] = useState('');
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    otp: '',
    newPass: '',
  });

  const [showText, setShowText] = useState({
    isPass: false,
    isNewPass: false,
    isReEnterPass: false,
  });

  const [errors, setErrors] = useState({
    usernameError: '',
    passwordError: '',
    otpError: '',
    newPasswordError: '',
  });
  const usernameRef = useRef();
  const passwordRef = useRef();

  const [validateLogin, { isLoading: loginResponseLoading, data: loginResponse, error: loginError }] = useLoginValidateMutation();

  useEffect(() => {
    if (loginResponse) {
      //TOdo
      const { token, name, id, company, email } = loginResponse;
      dispatch(setUserSession({ token, name, id, company, email }));
      localStorage.setItem('userDetails', JSON.stringify(loginResponse));
      setLoginErrorData('');
      setLoginResponse(loginResponse);
      setLoginSuccess(true);
    }
    if (loginError) {
      console.log(loginError);
      setLoginErrorData(loginError.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginResponse, loginError]);

  const validations = () => {
    const { username, password } = formData;
    const newErrors = { ...errors };
    newErrors.otpError = '';
    newErrors.newPasswordError = '';
    if (!username) {
      newErrors.usernameError = 'Please enter username';
    } else {
      newErrors.usernameError = '';
    }
    if (!password) {
      newErrors.passwordError = 'Please provide the password';
    } else {
      newErrors.passwordError = '';
    }

    newErrors.usernameError ? usernameRef.current.focus() : newErrors.passwordError && passwordRef.current.focus();
    setErrors(newErrors);
    const isError = Object.values(newErrors).some((k) => k !== '');
    return isError;
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleContinue = (e) => {
    e.preventDefault();
    if (validations()) {
      setLoginErrorData('');
    } else {
      validateLogin({
        eventId,
        loginData: { username: formData.username, password: formData.password },
      });
    }
  };

  const handleShowText = (name, value) => {
    setShowText({ ...showText, [name]: !value });
  };
  const handleBlur = () => {};
  const handleForgotPass = () => {};
  const handleRedirect = () => {};

  const onClose = () => {
    setOpen(false);
    setFormData({
      username: '',
      password: '',
      otp: '',
      newPass: '',
    });
    setErrors({
      usernameError: '',
      passwordError: '',
      otpError: '',
      newPasswordError: '',
    });
    if (location.pathname === '/workshops' || location.pathname === '/mentorships') {
      navigate('/');
    }
  };

  return (
    <Dialog
      open={open}
      sx={{
        width: '30%',
        margin: '0 auto',
        '& .MuiPaper-root': {
          borderRadius: '25px',
          width: '100%',
          boxShadow: 'none',
        },
        '& .MuiBackdrop-root ': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
      }}
      onClose={onClose}
      role='dialog'
      aria-modal='true'
      aria-label={`login`}
    >
      <Box>
        <Button
          sx={{
            float: 'right',
            right: '20px',
            top: '10px',
            position: 'absolute',
            fontSize: '30px !important',
            cursor: 'pointer',
            color: '#333333 !important',
            border: 'none',
            padding: '3px',
            backgroundColor: 'transparent',
            display: closeOption ? 'block' : 'none',
          }}
          onClick={onClose}
          aria-label='close Button'
        >
          <CloseSharpIcon />
        </Button>
      </Box>

      {successLogin && loginResponseData && !logoutSucess && <SuccessPopup dataText={'Login Successful'}></SuccessPopup>}
      {logoutSucess && !loggedInUser && !successLogin && <SuccessPopup dataText={'Logged Out'}></SuccessPopup>}
      {!successLogin && !loggedInUser && !logoutSucess && (
        <DialogContent
          style={{
            padding: '0px 40px 40px 40px',
            borderBottom: 'none',
            overflow: 'unset',
            borderRadius: '25px',
          }}
        >
          <LoginPopup
            formData={formData}
            usernameRef={usernameRef}
            passwordRef={passwordRef}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleForgotPass={handleForgotPass}
            handleRedirect={handleRedirect}
            handleContinue={handleContinue}
            handleShowText={handleShowText}
            errors={errors}
            showText={showText}
            loginErrorData={loginErrorData}
          ></LoginPopup>
        </DialogContent>
      )}
      {loginResponseLoading && (
        <LinearProgress
          sx={{
            backgroundColor: '#be854c',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#aeaeae',
            },
          }}
        ></LinearProgress>
      )}
    </Dialog>
  );
};

export default Login;
