/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppBar, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, Link, NavLink, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { setUserSession } from '../../api/session';
// import Login from '../Login/Login';
import { logo } from '../../assets/Images/Images';
import NavMenu from './NavMenu';
// import Popup from './Popup';
// import SignUpPopup from '../Registration/SignUpDialog';
// import _ from 'lodash';
import { NavHashLink } from 'react-router-hash-link';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'transparent !important',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: (props) => (props.mobile ? '16px 0' : props.windowsLaptop ? '16px 50px 13px !important' : '16px 78px 13px !important'),
    // backgroundColor: (props) => (props.scrollState === 'down' ? '#FFF' : 'transparent'),
    // backgroundColor: '#fff',
    transition: 'background-color 0.4s',
    // margin: '10px 0 !important',
  },
  brandLogo: {
    marginRight: '10px',
    '&:focus-visible': {
      // background: '#d61b32',
      boxShadow: 'none',
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
  },
  headerLinks: {
    display: 'flex',
    alignItems: 'center',
  },
  links: {
    // width: '100px',
    marginRight: '30px',
    textDecoration: 'none',
    //   padding: '0 20px',
    color: 'rgba(0,0,0,.9)',
    // fontWeight: '600',
    '&:focus-visible': {
      // background: '#d61b32',
      boxShadow: 'none',
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
  },
  headerBtns: {
    display: 'flex',
    '& div:first-child': {
      paddingRight: '20px',
    },
  },
  loginBtn: {
    fontSize: 14,
    padding: '12px 35px',
    borderRadius: 50,
    background: '#FFF',
    color: '#333333',
    minWidth: 125,
    fontFamily: 'Helvetica',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      background: '#d61b32',
      boxShadow: 'none',
    },
    '&:focus-visible': {
      background: '#d61b32',
      boxShadow: 'none',
      outline: 'none',
    },
    '&:disabled': {
      background: '#D6D6D6',
      color: '#333333',
      cursor: 'default',
    },
  },
  customButton: {
    fontSize: 14,
    padding: '12px 35px',
    borderRadius: 50,
    background: '#333333',
    color: '#fff',
    minWidth: 125,
    fontFamily: 'Helvetica',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      background: '#d61b32',
      boxShadow: 'none',
    },
    '&:focus-visible': {
      background: '#d61b32',
      boxShadow: 'none',
      outline: 'none',
    },
    '&:disabled': {
      background: '#D6D6D6',
      color: '#333333',
      cursor: 'default',
    },
  },
  userName: {
    color: '#000000',
    fontSize: 19,
    fontFamily: 'HelveticaforTargetRegular',
    cursor: 'default',
    textDecoration: 'none',
    '&:focus-visible': {
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
  },
  logout: {
    '&:focus-visible': {
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
  },
  visuallyHidden: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
  badgeContent: {
    '.MuiBadge-badge': {
      height: 13,
      minWidth: 13,
    },
  },
  notificationButton: {
    border: 'none',
    background: 'none',
    '&:focus-visible': {
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
  },
}));

const NavBar = ({ loggedInUser, storeLoggedInUser, storeAuthToken, storeNotificationStatus }) => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const location = useLocation();
  // const [getNotificationData, { data: notificationData }] = useLazyQuery(GET_NOTIFICATIONS);

  // const [getMobileUser] = useLazyQuery(LOGGED_IN_USER_PROFILE);

  // console.log('getMobileUser', getMobileUser());
  // const [readAlerts] = useMutation(READ_ALERTS);

  let listener = null;
  const [scrollState, setScrollState] = useState('');
  useEffect(() => {
    function delayHashLink() {
      setTimeout(() => {
        if (location.hash === '#speakers') {
          const section = document.querySelector('#speakers');
          if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
          } else {
            delayHashLink();
          }
        }
      }, 1000);
    }
    delayHashLink();
  }, []);
  // const location = useLocation();
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    listener = document.addEventListener('scroll', (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      // const { pathname } = location;
      if (scrolled >= 30) {
        if (scrollState !== 'down') {
          setScrollState('down');
        }
      } else {
        if (scrollState !== 'top') {
          setScrollState('top');
        }
      }
    });
    return () => {
      document.removeEventListener('scroll', listener);
    };
  }, [scrollState]);

  const windowsLaptop = useMediaQuery((theme) => theme.breakpoints.between(1024, 1700));
  const classes = useStyles({ mobile, scrollState, windowsLaptop });
  const dispatch = useDispatch();
  const [successLogin, setLoginSuccess] = useState(false);
  const [logoutSucess, setLogoutSucces] = useState(false);
  const logOut = () => {
    const authToken = '';
    const loggedInUser = '';
    const userId = '';
    localStorage.removeItem('userDetails');
    dispatch(setUserSession({ authToken, loggedInUser, userId }));
    setOpen(true);
    setLoginSuccess(false);
    setLogoutSucces(true);
  };

  const [open, setOpen] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [mobileNotify, setMobileNotify] = useState(false);

  return (
    <>
      {mobile ? (
        // for mobile view
        !mobileNotify && (
          <AppBar elevation={0} className={classes.appBar}>
            <Toolbar className={classes.toolBar} sx={{ backgroundColor: scrollState === 'down' ? '#FFF' : 'transparent' }}>
              <Link to='/' className={classes.brandLogo}>
                <img src={logo} alt='Target Elevate Logo' height='54' style={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
              </Link>
              {/* <Popup open={open} handleClose={handleClose} message={'Logout Successful!'} /> */}
              {mobile ? (
                <NavMenu
                  open={open}
                  setLogoutSucces={setLogoutSucces}
                  setOpen={setOpen}
                  loggedInUser={loggedInUser}
                  logOut={logOut}
                  logoutSucess={logoutSucess}
                  successLogin={successLogin}
                  setLoginSuccess={setLoginSuccess}
                  // handleNotifications={handleNotifications}
                />
              ) : null}
            </Toolbar>
          </AppBar>
        )
      ) : (
        //for desktop view
        <AppBar elevation={0} className={classes.appBar}>
          <Toolbar className={classes.toolBar} sx={{ backgroundColor: scrollState === 'down' ? '#FFF' : 'transparent' }}>
            <div>
              {/* below h1 is for keyboard users */}
              <h1 className={classes.visuallyHidden}>Target Elevate </h1>
              <Link to='/' className={classes.brandLogo}>
                <img src={logo} alt='Target Elevate Logo' height='54' style={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
              </Link>
            </div>
            {/* <Popup open={open} handleClose={handleClose} message={'Logout Successful!'} /> */}

            <div className={classes.headerLinks}>
              <NavLink
                to='/'
                className={classes.links}
                style={({ isActive }) => ({
                  color: location.hash === '' && location.pathname === '/' ? '#333333' : '#666666',
                  fontWeight: location.hash === '' && location.pathname === '/' && 'bold',
                })}
              >
                Home
              </NavLink>
              <NavLink
                to='/why-elevate'
                className={classes.links}
                style={({ isActive }) => ({
                  color: isActive ? '#333333' : '#666666',
                  fontWeight: isActive && 'bold',
                })}
              >
                Why Elevate
              </NavLink>
              <NavHashLink
                to='/#speakers'
                className={classes.links}
                scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                style={({ isActive }) => ({
                  color: location.hash === '#speakers' && location.pathname === '/' ? '#333333' : '#666666',
                  fontWeight: location.hash === '#speakers' && location.pathname === '/' && 'bold',
                })}
              >
                Speakers
              </NavHashLink>
              <NavLink
                to='/agenda'
                className={classes.links}
                style={({ isActive }) => ({
                  color: isActive ? '#333333' : '#666666',
                  fontWeight: isActive && 'bold',
                })}
              >
                Agenda
              </NavLink>
              <NavLink
                to='/mentorship'
                className={classes.links}
                style={({ isActive }) => ({
                  color: isActive ? '#333333' : '#666666',
                  fontWeight: isActive && 'bold',
                })}
              >
                Mentorship
              </NavLink>
              <NavLink
                to='/highlights'
                className={classes.links}
                style={({ isActive }) => ({
                  color: isActive ? '#333333' : '#666666',
                  fontWeight: isActive && 'bold',
                })}
              >
                Past Highlights
              </NavLink>
              <NavLink
                to='/gallery'
                className={classes.links}
                style={({ isActive }) => ({
                  color: isActive ? '#333333' : '#666666',
                  fontWeight: isActive && 'bold',
                })}
              >
                Gallery
              </NavLink>
              <NavLink
                to='/FAQ'
                className={classes.links}
                style={({ isActive }) => ({
                  color: isActive ? '#333333' : '#666666',
                  fontWeight: isActive && 'bold',
                })}
              >
                FAQs
              </NavLink>
              {/* {_.isEmpty(loggedInUser) ? (
                <div style={{ marginRight: '0', padding: '5px 20px', borderRadius: '50px', border: '1px solid #CC0000' }}>
                  {' '}
                  <SignUpPopup isheader={true} />
                </div>
              ) : (
                ''
              )} */}
            </div>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

// export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
export default NavBar;
